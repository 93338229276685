export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: Date; output: Date; }
  JSON: { input: any; output: any; }
};

export type AiFeedback = {
  __typename?: 'AIFeedback';
  feedback: Scalars['String']['output'];
  grade: Scalars['Float']['output'];
};

export type AiQuestionFeedbackInput = {
  answer: Scalars['String']['input'];
  blockId?: InputMaybe<Scalars['ID']['input']>;
  interactiveContentId?: InputMaybe<Scalars['ID']['input']>;
  language: Scalars['String']['input'];
};

export enum AccessType {
  InviteOnly = 'INVITE_ONLY',
  Open = 'OPEN',
  RequestToJoin = 'REQUEST_TO_JOIN'
}

export enum ActivityType {
  Lab = 'LAB',
  PracticalLearning = 'PRACTICAL_LEARNING',
  Product = 'PRODUCT',
  Review = 'REVIEW'
}

export type AddUserBadgeInput = {
  badgeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type AnswerCount = {
  __typename?: 'AnswerCount';
  answerId: Scalars['ID']['output'];
  count: Scalars['Int']['output'];
};

export type AnswerOption = {
  __typename?: 'AnswerOption';
  description: Array<TranslatedField>;
  explication?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID']['output'];
  isCorrectAnswer?: Maybe<Scalars['Boolean']['output']>;
};

export type AnswerOptionGroup = {
  __typename?: 'AnswerOptionGroup';
  answers: Array<AnswerOption>;
  id: Scalars['ID']['output'];
};

export type AnswerOptionGroupInput = {
  answers: Array<AnswerOptionInput>;
};

export type AnswerOptionInput = {
  description: Array<TranslatedFieldInput>;
  explication?: InputMaybe<Array<TranslatedFieldInput>>;
  isCorrectAnswer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApiKey = {
  __typename?: 'ApiKey';
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
};

export type AssignDeliverablesInput = {
  assignToUserId: Scalars['ID']['input'];
  cohortId: Scalars['ID']['input'];
  interactiveContentId: Scalars['ID']['input'];
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Assignment = {
  __typename?: 'Assignment';
  branch: Scalars['String']['output'];
  description?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID']['output'];
  repoURL: Scalars['String']['output'];
  solutionFilePaths?: Maybe<Array<Scalars['String']['output']>>;
  solutions?: Maybe<Array<AssignmentFile>>;
  testCommand: Scalars['String']['output'];
  testCommandWindows: Scalars['String']['output'];
  tests: Array<AssignmentFile>;
  token: Scalars['String']['output'];
};

export type AssignmentFile = {
  __typename?: 'AssignmentFile';
  code: Scalars['String']['output'];
  filePath: Scalars['String']['output'];
};

export type AssignmentFileInput = {
  code: Scalars['String']['input'];
  filePath: Scalars['String']['input'];
};

export type AssignmentInput = {
  branch: Scalars['String']['input'];
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  repoURL: Scalars['String']['input'];
  solutions?: InputMaybe<Array<AssignmentFileInput>>;
  testCommand: Scalars['String']['input'];
  testCommandWindows: Scalars['String']['input'];
  tests: Array<AssignmentFileInput>;
};

export enum AssignmentStatus {
  Created = 'CREATED',
  Submitted = 'SUBMITTED'
}

export type AssistantReplyContextInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
};

export type Attendance = {
  __typename?: 'Attendance';
  attendanceCheck: AttendanceCheck;
  calendarEventId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  schoolId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type AttendanceCheck = {
  __typename?: 'AttendanceCheck';
  firstCheck?: Maybe<Scalars['DateTime']['output']>;
  secondCheck?: Maybe<Scalars['DateTime']['output']>;
};

export type AttendanceResponse = {
  __typename?: 'AttendanceResponse';
  createdAttendance: Attendance;
  liveSessionLink: Scalars['String']['output'];
};

export type AwardCertificateInput = {
  badgeId: Scalars['ID']['input'];
  certifierOrgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  cohortId: Scalars['ID']['input'];
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  partnerOrgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  template: Scalars['String']['input'];
  usersIds: Array<Scalars['ID']['input']>;
};

export type AwardCoinsInput = {
  cohortId: Scalars['ID']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  userCoins: Array<AwardCoinsToUser>;
};

export type AwardCoinsToUser = {
  amount: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type Badge = {
  __typename?: 'Badge';
  description: Array<TranslatedField>;
  descriptors?: Maybe<Array<Descriptor>>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Array<TranslatedField>;
  userHasBadge: Scalars['Boolean']['output'];
};


export type BadgeUserHasBadgeArgs = {
  userId: Scalars['ID']['input'];
};

export type BaseSchool = {
  allowedLanguages?: Maybe<Array<Scalars['String']['output']>>;
  conductCodeUrl?: Maybe<Scalars['String']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  frontendFeatures: Array<Scalars['String']['output']>;
  gradingPolicyUrl?: Maybe<Scalars['String']['output']>;
  headerAccent?: Maybe<Scalars['String']['output']>;
  headerColor?: Maybe<Scalars['String']['output']>;
  heroBackground?: Maybe<Scalars['String']['output']>;
  heroImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedInId?: Maybe<Scalars['String']['output']>;
  loginLogoUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onboardingFlowId?: Maybe<Scalars['ID']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  privacyUrl?: Maybe<Scalars['String']['output']>;
  termsUrl?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Theme>;
};

export type BatchProcess = {
  __typename?: 'BatchProcess';
  cohortId: Scalars['ID']['output'];
  courseId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: BatchProcessStatus;
};

export enum BatchProcessStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING'
}

export type Block = {
  __typename?: 'Block';
  blockType: Scalars['String']['output'];
  category: Scalars['String']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  flowId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Array<TranslatedField>;
  nodeId: Scalars['ID']['output'];
  order?: Maybe<Scalars['String']['output']>;
  privateData?: Maybe<Scalars['JSON']['output']>;
  userGrade?: Maybe<FlowsGrade>;
  userProgress?: Maybe<FlowsProgress>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  allDay: Scalars['Boolean']['output'];
  cohort?: Maybe<Cohort>;
  course: Course;
  courseId: Scalars['ID']['output'];
  duration: Scalars['Float']['output'];
  end: Scalars['DateTime']['output'];
  hasCalendarEventStarted?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isCalendarEventInThePast?: Maybe<Scalars['Boolean']['output']>;
  liveSessionLink: Scalars['String']['output'];
  presentationLink?: Maybe<Scalars['String']['output']>;
  recordingLink?: Maybe<Scalars['String']['output']>;
  responsibles: Array<Responsible>;
  responsiblesComments: Array<ResponsibleComment>;
  responsiblesCount: Scalars['Int']['output'];
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['ID']['output']>;
  start: Scalars['DateTime']['output'];
};

export type Certificate = {
  __typename?: 'Certificate';
  badge: Badge;
  certifierOrgs?: Maybe<Array<PublicOrganization>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Array<TranslatedField>>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  imageURL?: Maybe<Scalars['String']['output']>;
  partnerOrgs?: Maybe<Array<PublicOrganization>>;
  pdfURL?: Maybe<Scalars['String']['output']>;
  permalink: Scalars['String']['output'];
  template: Scalars['String']['output'];
  user: PublicUser;
};


export type CertificateImageUrlArgs = {
  language: Scalars['String']['input'];
};


export type CertificatePdfUrlArgs = {
  language: Scalars['String']['input'];
};

export type ChangeHistory = {
  __typename?: 'ChangeHistory';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  typeOfChange: ChangeType;
  user: User;
};

export enum ChangeType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Modified = 'MODIFIED'
}

export type ChannelConfig = DashboardNotificationConfig | DiscordNotificationConfig | EmailNotificationConfig | SlackNotificationConfig;

export type CodeRunnerTest = {
  __typename?: 'CodeRunnerTest';
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CodeRunnerTestInput = {
  code: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type Codelab = {
  __typename?: 'Codelab';
  assignmentIds: Array<Scalars['ID']['output']>;
  assignments: Array<CodelabAssignment>;
  branch: Scalars['String']['output'];
  description: Array<TranslatedField>;
  flowId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  repoURL: Scalars['String']['output'];
};

export type CodelabAssignment = {
  __typename?: 'CodelabAssignment';
  codelabId: Scalars['ID']['output'];
  description: Array<TranslatedField>;
  id: Scalars['ID']['output'];
  programmingLanguage: Scalars['String']['output'];
  solutions?: Maybe<Array<AssignmentFile>>;
  test?: Maybe<AssignmentFile>;
  testCommand: Scalars['String']['output'];
  testCommandWindows: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
};

export type CodelabResponse = {
  __typename?: 'CodelabResponse';
  codelabAssignmentId: Scalars['ID']['output'];
  codelabId: Scalars['ID']['output'];
  codelabResponses: Array<AssignmentFile>;
  createdAt: Scalars['DateTime']['output'];
  flowId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  schoolId: Scalars['ID']['output'];
  status: AssignmentStatus;
  team?: Maybe<NexusTeam>;
  teamId: Scalars['ID']['output'];
  teamResponses: Array<CodelabResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type Cohort = {
  __typename?: 'Cohort';
  batchProcess?: Maybe<BatchProcess>;
  calendarEvents: Array<CalendarEvent>;
  calendarEventsDuration: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  courseId: Array<Scalars['ID']['output']>;
  courses: Array<Course>;
  coursesWithModules: CoursesWithModules;
  defaultLiveSessionLink?: Maybe<Scalars['String']['output']>;
  deliverable?: Maybe<Deliverable>;
  deliverables: Array<Deliverable>;
  discordChannel?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  invites: Array<Invite>;
  prices?: Maybe<Array<Price>>;
  program?: Maybe<Program>;
  sessionDefaultDuration: Scalars['Int']['output'];
  sessionStatus: CohortSessionStatus;
  slackChannel?: Maybe<Scalars['String']['output']>;
  sponsors?: Maybe<Array<PublicOrganization>>;
  start: Scalars['DateTime']['output'];
  status: CohortStatus;
  teams: Array<Team>;
  timeZone: Scalars['String']['output'];
  training?: Maybe<Training>;
  tutors?: Maybe<Array<PublicUser>>;
  tutorsIds?: Maybe<Array<Scalars['ID']['output']>>;
  userCurrentCourse?: Maybe<Course>;
  userCurrentCourseProgress?: Maybe<Progress>;
  userCurrentModule?: Maybe<Module>;
  userGrade?: Maybe<Grade>;
  userProgress?: Maybe<Progress>;
  users: Array<User>;
  visibility: CohortVisibility;
  weekdays: Array<Scalars['Int']['output']>;
};


export type CohortBatchProcessArgs = {
  courseId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  status: BatchProcessStatus;
};


export type CohortCalendarEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CohortCoursesArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type CohortCoursesWithModulesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startModuleId?: InputMaybe<Scalars['ID']['input']>;
};


export type CohortDeliverableArgs = {
  deliverableId: Scalars['ID']['input'];
};


export type CohortDeliverablesArgs = {
  interactiveContentId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type CohortProgramArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type CohortTrainingArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type CohortUsersArgs = {
  userSearchInput?: InputMaybe<EntitySearchInput>;
};

export enum CohortSessionStatus {
  Ended = 'ENDED',
  GracePeriod = 'GRACE_PERIOD',
  InProgress = 'IN_PROGRESS'
}

export enum CohortStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum CohortType {
  Course = 'COURSE',
  Program = 'PROGRAM'
}

export enum CohortVisibility {
  Private = 'PRIVATE',
  Protected = 'PROTECTED',
  Public = 'PUBLIC'
}

export type CohortWithRole = {
  __typename?: 'CohortWithRole';
  cohort: Cohort;
  isBlocked: Scalars['Boolean']['output'];
  roles: Array<UserRole>;
};

export type Coins = {
  __typename?: 'Coins';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type Community = {
  __typename?: 'Community';
  accessType: AccessType;
  entities: Array<Entity>;
  id: Scalars['ID']['output'];
  members: Array<PublicUser>;
  name: Scalars['String']['output'];
};

export type CompleteCodelabInput = {
  blockId: Scalars['ID']['input'];
  codelabAssignmentId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
  solutionFiles?: InputMaybe<Array<AssignmentFileInput>>;
  token: Scalars['String']['input'];
};

export type Connection = {
  __typename?: 'Connection';
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  sourceId: Scalars['ID']['output'];
  targetId: Scalars['ID']['output'];
};

export type ContentEngagementTracking = {
  __typename?: 'ContentEngagementTracking';
  cohortId: Scalars['ID']['output'];
  engagement: Scalars['Int']['output'];
  interactiveContentId: Scalars['ID']['output'];
  userId: Scalars['ID']['output'];
};

export type ContentEngagementTrackingInput = {
  blockId?: InputMaybe<Scalars['ID']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  engagement: Scalars['Int']['input'];
  interactiveContentId?: InputMaybe<Scalars['ID']['input']>;
};

export type Course = {
  __typename?: 'Course';
  badges?: Maybe<Array<Badge>>;
  badgesCount: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  cohorts: Array<Cohort>;
  description?: Maybe<Array<TranslatedField>>;
  details?: Maybe<Array<TranslatedField>>;
  generationType: GenerationType;
  holidays?: Maybe<Array<Holiday>>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  interactiveContents: Array<InteractiveContent>;
  mentors: Array<User>;
  moduleCount: Scalars['Int']['output'];
  modules: Array<Module>;
  organizations: Array<PublicOrganizationWithRole>;
  school: School;
  sessions: Array<Session>;
  sessionsDuration: Scalars['Float']['output'];
  sprints: Array<Sprint>;
  status: CourseStatus;
  students: Array<User>;
  translatedName?: Maybe<Array<TranslatedField>>;
  userBadges?: Maybe<Array<Badge>>;
  userBadgesCount: Scalars['Int']['output'];
  userCurrentModule?: Maybe<Module>;
  userGrade?: Maybe<Grade>;
  userProgress?: Maybe<Progress>;
  users: Array<User>;
  visibility: CourseVisibility;
};


export type CourseBadgesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type CourseCohortsArgs = {
  cohortType?: InputMaybe<CohortType>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<CohortVisibility>;
};


export type CourseInteractiveContentsArgs = {
  interactiveContentTypes: Array<InteractiveContentType>;
};


export type CourseModulesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CourseOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type CourseUserCurrentModuleArgs = {
  cohortId: Scalars['ID']['input'];
};


export type CourseUserGradeArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
};


export type CourseUserProgressArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
};

export type CourseCohortPrice = {
  __typename?: 'CourseCohortPrice';
  courses: Array<CourseDetails>;
  invitationError?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  prices?: Maybe<Array<Price>>;
  program?: Maybe<ProgramDetails>;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type CourseDetails = {
  __typename?: 'CourseDetails';
  badges?: Maybe<Array<PublicBadge>>;
  courseId: Scalars['ID']['output'];
  description?: Maybe<Array<TranslatedField>>;
  details?: Maybe<Array<TranslatedField>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated use 'translatedName' instead */
  name?: Maybe<Scalars['String']['output']>;
  translatedName: Array<TranslatedField>;
};

export enum CourseStatus {
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum CourseVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CoursesWithModules = {
  __typename?: 'CoursesWithModules';
  courses: Array<Course>;
  modules: Array<Module>;
  nextModule?: Maybe<Module>;
  prevModule?: Maybe<Module>;
};

export type CreateBadgeInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  descriptors?: InputMaybe<Array<DescriptorInput>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Array<TranslatedFieldInput>;
};

export type CreateBlockInput = {
  blockType: Scalars['String']['input'];
  category: Scalars['String']['input'];
  data?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  name: Array<TranslatedFieldInput>;
  nodeId: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['String']['input']>;
  privateData?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  duration: Scalars['Float']['input'];
  liveSessionLink: Scalars['String']['input'];
  presentationLink?: InputMaybe<Scalars['String']['input']>;
  recordingLink?: InputMaybe<Scalars['String']['input']>;
  responsibles?: InputMaybe<Array<ResponsibleInput>>;
  sessionId: Scalars['ID']['input'];
  start: Scalars['DateTime']['input'];
};

export type CreateCertificateInput = {
  badgeId: Scalars['ID']['input'];
  certifierOrgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  partnerOrgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  template: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type CreateChangeHistoryInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  typeOfChange: ChangeType;
};

export type CreateCodelabAssignmentInput = {
  codelabId: Scalars['ID']['input'];
  description: Array<TranslatedFieldInput>;
  programmingLanguage?: InputMaybe<Scalars['String']['input']>;
  solutions?: InputMaybe<Array<AssignmentFileInput>>;
  test?: InputMaybe<AssignmentFileInput>;
  testCommand?: InputMaybe<Scalars['String']['input']>;
  testCommandWindows?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCodelabInput = {
  branch?: InputMaybe<Scalars['String']['input']>;
  description: Array<TranslatedFieldInput>;
  flowId: Scalars['ID']['input'];
  repoURL: Scalars['String']['input'];
};

export type CreateCohortInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  courseId?: InputMaybe<Array<Scalars['ID']['input']>>;
  defaultLiveSessionLink?: InputMaybe<Scalars['String']['input']>;
  discordChannel?: InputMaybe<Scalars['String']['input']>;
  prices?: InputMaybe<Array<PriceInput>>;
  programId?: InputMaybe<Scalars['ID']['input']>;
  sessionDefaultDuration?: InputMaybe<Scalars['Int']['input']>;
  sessionStatus?: InputMaybe<CohortSessionStatus>;
  slackChannel?: InputMaybe<Scalars['String']['input']>;
  sponsorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  start: Scalars['DateTime']['input'];
  status?: InputMaybe<CohortStatus>;
  timeZone: Scalars['String']['input'];
  tutorsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  visibility: CohortVisibility;
  weekdays?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CreateCommunityInput = {
  accessType?: InputMaybe<AccessType>;
  name: Scalars['String']['input'];
};

export type CreateConnectionInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  flowId: Scalars['ID']['input'];
  sourceId: Scalars['ID']['input'];
  targetId: Scalars['ID']['input'];
};

export type CreateCourseInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  details?: InputMaybe<Array<TranslatedFieldInput>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<PublicOrganizationWithRoleInput>>;
  status?: InputMaybe<CourseStatus>;
  translatedName: Array<TranslatedFieldInput>;
  visibility?: InputMaybe<CourseVisibility>;
};

export type CreateDeliverableInput = {
  codeRunnerCheck?: InputMaybe<Scalars['String']['input']>;
  codeRunnerResults?: InputMaybe<Scalars['String']['input']>;
  cohortId: Scalars['ID']['input'];
  deliverableContent?: InputMaybe<Scalars['String']['input']>;
  deliverableUrl?: InputMaybe<Scalars['String']['input']>;
  fileUploadUrl?: InputMaybe<Scalars['String']['input']>;
  interactiveContentId: Scalars['ID']['input'];
  userLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDiscordChannelInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFlowInput = {
  name: Array<TranslatedFieldInput>;
};

export type CreateFlowsGradeInput = {
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  grade: Scalars['Float']['input'];
  response?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateFlowsProgressInput = {
  blockId?: InputMaybe<Scalars['ID']['input']>;
  nodeId?: InputMaybe<Scalars['ID']['input']>;
  progress: Scalars['Int']['input'];
  response?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateFormInput = {
  allowResponseUpdate: Scalars['Boolean']['input'];
  courseId: Scalars['ID']['input'];
  isGradeable: Scalars['Boolean']['input'];
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  shuffledAnswers: Scalars['Boolean']['input'];
};

export type CreateFormInstanceInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  formId: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateFormResponseInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  entity?: InputMaybe<EntityName>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  formId?: InputMaybe<Scalars['ID']['input']>;
  formInstanceId?: InputMaybe<Scalars['ID']['input']>;
  responses: Array<QuestionResponseInput>;
};

export type CreateGradeInput = {
  cohortId: Scalars['ID']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  grade: Scalars['Float']['input'];
  ignoreCreatedBy?: InputMaybe<Scalars['Boolean']['input']>;
  response?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type CreateInteractiveContentInput = {
  assignment?: InputMaybe<AssignmentInput>;
  codeRunnerGradePercentage?: InputMaybe<Scalars['Int']['input']>;
  codeRunnerTests?: InputMaybe<Array<CodeRunnerTestInput>>;
  codeRunnerType?: InputMaybe<Scalars['String']['input']>;
  contentType: InteractiveContentType;
  courseId: Scalars['ID']['input'];
  defaultDeliverableContent?: InputMaybe<Scalars['String']['input']>;
  deliverableGradingMethod?: InputMaybe<DeliverableGradingMethod>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  formId?: InputMaybe<Scalars['ID']['input']>;
  hiddenDeliverableContent?: InputMaybe<Scalars['String']['input']>;
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  preliminaryContent?: InputMaybe<Array<TranslatedFieldInput>>;
  programmingLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  prompt?: InputMaybe<Array<TranslatedFieldInput>>;
  question?: InputMaybe<Array<TranslatedFieldInput>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['ID']['input'];
  solution?: InputMaybe<AssignmentFileInput>;
  teamAssignment?: InputMaybe<TeamAssignmentInput>;
  title: Array<TranslatedFieldInput>;
  video?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type CreateMentorAvailability = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
  status: MentorAvailabilityStatus;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateModuleInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  courseId: Scalars['ID']['input'];
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  name: Array<TranslatedFieldInput>;
};

export type CreateNexusTeamInput = {
  flowId: Scalars['ID']['input'];
  isIndividualWork: Scalars['Boolean']['input'];
  memberIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateNodeInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  flowId: Scalars['ID']['input'];
  name: Array<TranslatedFieldInput>;
  nodeType: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  position: NodePositionInput;
  size?: InputMaybe<NodeSizeInput>;
};

export type CreateNotificationInput = {
  channel: NotificationChannel;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  discordChannel?: InputMaybe<Scalars['String']['input']>;
  message: Array<TranslatedFieldInput>;
  notificationTime: Scalars['DateTime']['input'];
  recipientId?: InputMaybe<Scalars['ID']['input']>;
  recipientType: Scalars['String']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  slackChannel?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Array<TranslatedFieldInput>>;
  text?: InputMaybe<Array<TranslatedFieldInput>>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrderInput = {
  currency: Scalars['String']['input'];
  items: Array<OrderItemInput>;
  paymentMethodType: PaymentMethod;
  promoCode?: InputMaybe<Scalars['String']['input']>;
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOrganizationInput = {
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateProgramInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  items: Array<ProgramItemInput>;
  name: Scalars['String']['input'];
  status: CourseStatus;
  visibility?: InputMaybe<CourseVisibility>;
};

export type CreateProgressInput = {
  cohortId: Scalars['ID']['input'];
  interactiveContentId: Scalars['ID']['input'];
  progress: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreatePromoCodeInput = {
  code: Scalars['String']['input'];
  cohortIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  discount: Scalars['Int']['input'];
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateQuestionInput = {
  answerGroups?: InputMaybe<Array<AnswerOptionGroupInput>>;
  answers: Array<AnswerOptionInput>;
  description: Array<TranslatedFieldInput>;
  questionType?: InputMaybe<QuestionType>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateQuestionSurveyInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  options?: InputMaybe<Array<OptionSurveyInput>>;
  title: Array<TranslatedFieldInput>;
};

export type CreateQuizInput = {
  flowId: Scalars['ID']['input'];
  isGradeable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateQuizQuestionInput = {
  answerGroups?: InputMaybe<Array<AnswerOptionGroupInput>>;
  answers?: InputMaybe<Array<AnswerOptionInput>>;
  description: Array<TranslatedFieldInput>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateQuizResponseInput = {
  blockId: Scalars['ID']['input'];
  quizId: Scalars['ID']['input'];
  selectedAnswersIds: Array<Scalars['ID']['input']>;
};

export type CreateSchoolInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subdomain: Scalars['String']['input'];
};

export type CreateSecondCheckNotificationInput = {
  baseURL: Scalars['String']['input'];
  calendarEventId: Scalars['ID']['input'];
};

export type CreateSessionInput = {
  activityType: ActivityType;
  courseId: Scalars['ID']['input'];
  duration?: InputMaybe<Scalars['Float']['input']>;
  goals?: InputMaybe<Array<LearningGoalInput>>;
  moduleId: Scalars['ID']['input'];
  name: Array<TranslatedFieldInput>;
  prerecordingLink?: InputMaybe<Scalars['String']['input']>;
  presentationLink?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSurveyInput = {
  badgeId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  isActive: Scalars['Boolean']['input'];
  name: Array<TranslatedFieldInput>;
  questions: Array<CreateQuestionSurveyInput>;
};

export type CreateSurveyOptionResponseInput = {
  optionId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSurveyQuestionResponseInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['ID']['input'];
  selectOptions?: InputMaybe<Array<CreateSurveyOptionResponseInput>>;
};

export type CreateSurveyResponseInput = {
  answers: Array<CreateSurveyQuestionResponseInput>;
  surveyId: Scalars['ID']['input'];
};

export type CreateTeamAssignmentsInput = {
  codelabId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};

export type CreateTeamInput = {
  cohortId: Scalars['ID']['input'];
  memberIds: Array<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type CreateUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firebaseUid?: InputMaybe<Scalars['String']['input']>;
  gitHubUsername?: InputMaybe<Scalars['String']['input']>;
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  password?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
  uploadedAvatarUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkReportInput = {
  category: Scalars['String']['input'];
  comments: Scalars['String']['input'];
  totalHours: Scalars['Float']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type DashboardNotificationConfig = {
  __typename?: 'DashboardNotificationConfig';
  userId: Scalars['ID']['output'];
};

export type DeleteFormResponseInput = {
  cohortId: Scalars['ID']['input'];
  entityId: Scalars['ID']['input'];
  formResponseId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Deliverable = {
  __typename?: 'Deliverable';
  assignedTo?: Maybe<User>;
  assignedToUserId?: Maybe<Scalars['ID']['output']>;
  cohort: Cohort;
  cohortId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deliverableCategory: DeliverableGradingMethod;
  deliverableContent?: Maybe<Scalars['String']['output']>;
  deliverableInstance?: Maybe<DeliverableInstance>;
  deliverableUrl?: Maybe<Scalars['String']['output']>;
  fileUploadUrl?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID']['output'];
  interactiveContent?: Maybe<InteractiveContent>;
  interactiveContentId: Scalars['ID']['output'];
  isBlocked: Scalars['Boolean']['output'];
  lastUserUpdate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};


export type DeliverableDeliverableInstanceArgs = {
  cohortId: Scalars['ID']['input'];
};

export enum DeliverableGradingMethod {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type DeliverableInstance = {
  __typename?: 'DeliverableInstance';
  cohortId: Scalars['ID']['output'];
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  interactiveContent?: Maybe<InteractiveContent>;
  interactiveContentId: Scalars['ID']['output'];
};

export type DeliverableInstances = {
  __typename?: 'DeliverableInstances';
  cohortId: Scalars['ID']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type Descriptor = {
  __typename?: 'Descriptor';
  description: Array<TranslatedField>;
  skillType: SkillType;
};

export type DescriptorInput = {
  description: Array<TranslatedFieldInput>;
  skillType: SkillType;
};

export type DiscordConfig = {
  __typename?: 'DiscordConfig';
  botToken: Scalars['String']['output'];
  cohortsChannelId: Scalars['String']['output'];
  feedbackChannelId: Scalars['String']['output'];
  serverId: Scalars['String']['output'];
};

export type DiscordConfigInput = {
  botToken: Scalars['String']['input'];
  cohortsChannelId: Scalars['String']['input'];
  feedbackChannelId: Scalars['String']['input'];
  serverId: Scalars['String']['input'];
};

export type DiscordNotificationConfig = {
  __typename?: 'DiscordNotificationConfig';
  discordChannel: Scalars['String']['output'];
};

export type DisenrollUserInput = {
  cohortId: Scalars['ID']['input'];
  roleInCohort?: InputMaybe<UserRole>;
  userId: Scalars['ID']['input'];
};

export type EmailNotificationConfig = {
  __typename?: 'EmailNotificationConfig';
  emails: Array<Scalars['String']['output']>;
  subject?: Maybe<Array<TranslatedField>>;
  text?: Maybe<Array<TranslatedField>>;
};

export type EnrollUserInput = {
  cohortId: Scalars['ID']['input'];
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  roleInCohort: UserRole;
  userId: Scalars['ID']['input'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  excludeFromDeliverables?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isBlocked: Scalars['Boolean']['output'];
  referrerCode?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
};

export type Entity = {
  __typename?: 'Entity';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export enum EntityName {
  Badge = 'BADGE',
  Block = 'BLOCK',
  CalendarEvent = 'CALENDAR_EVENT',
  Codelab = 'CODELAB',
  Cohort = 'COHORT',
  Coins = 'COINS',
  Course = 'COURSE',
  Deliverable = 'DELIVERABLE',
  Feedback = 'FEEDBACK',
  Flow = 'FLOW',
  Form = 'FORM',
  FormResponse = 'FORM_RESPONSE',
  Grade = 'GRADE',
  InteractiveContent = 'INTERACTIVE_CONTENT',
  MentorAvailability = 'MENTOR_AVAILABILITY',
  Module = 'MODULE',
  Node = 'NODE',
  Organization = 'ORGANIZATION',
  Program = 'PROGRAM',
  Progress = 'PROGRESS',
  Quiz = 'QUIZ',
  School = 'SCHOOL',
  Session = 'SESSION',
  User = 'USER'
}

export type EntitySearchInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Scalars['JSON']['input']>;
  flowId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nodeId?: InputMaybe<Scalars['ID']['input']>;
  nodeType?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  programId?: InputMaybe<Scalars['ID']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  sort?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type Experience = {
  __typename?: 'Experience';
  experience: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['Int']['output'];
  nextLevelExperience: Scalars['Int']['output'];
  prevLevelExperience: Scalars['Int']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  cohortId: Scalars['ID']['output'];
  courseId: Scalars['ID']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  pageUrl?: Maybe<Scalars['String']['output']>;
  ratings: Array<FeedbackRating>;
  user?: Maybe<User>;
};

export type FeedbackInput = {
  cohortId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  pageUrl?: InputMaybe<Scalars['String']['input']>;
  ratings: Array<FeedbackRatingInput>;
};

export type FeedbackRating = {
  __typename?: 'FeedbackRating';
  code: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
};

export type FeedbackRatingInput = {
  code: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
};

export type Flow = {
  __typename?: 'Flow';
  badges: Array<Badge>;
  communities: Array<Community>;
  connections: Array<Connection>;
  creator?: Maybe<PublicUser>;
  description: Array<TranslatedField>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['String']['output']>;
  name: Array<TranslatedField>;
  nodes: Array<Node>;
  participantsCommunityId: Scalars['ID']['output'];
  participantsCount: Scalars['Int']['output'];
  rating: Rating;
  status: FlowStatus;
  topics: Array<Scalars['String']['output']>;
  userHasFlowBadges: Scalars['Boolean']['output'];
  userIsParticipant: Scalars['Boolean']['output'];
  userNodesProgress: Array<FlowsProgress>;
  visibility: FlowVisibility;
};


export type FlowCommunitiesArgs = {
  search?: InputMaybe<EntitySearchInput>;
};

export type FlowFilter = {
  __typename?: 'FlowFilter';
  category: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Array<TranslatedField>;
  value: Scalars['String']['output'];
};

export enum FlowStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum FlowVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type FlowsGrade = {
  __typename?: 'FlowsGrade';
  attempts: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  feedback?: Maybe<Scalars['String']['output']>;
  grade: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  response?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type FlowsProgress = {
  __typename?: 'FlowsProgress';
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  progress: Scalars['Int']['output'];
  response?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type FlowsWithFilters = {
  __typename?: 'FlowsWithFilters';
  filters: Array<FlowFilter>;
  flows: Array<Flow>;
};

export type Form = {
  __typename?: 'Form';
  allowResponseUpdate: Scalars['Boolean']['output'];
  course?: Maybe<Course>;
  courseId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isGradeable: Scalars['Boolean']['output'];
  name?: Maybe<Array<TranslatedField>>;
  questions: Array<Question>;
  shuffledAnswers: Scalars['Boolean']['output'];
};

export type FormInstance = {
  __typename?: 'FormInstance';
  cohort: Cohort;
  end?: Maybe<Scalars['DateTime']['output']>;
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  form: Form;
  id: Scalars['ID']['output'];
  responseCount: Array<AnswerCount>;
  start?: Maybe<Scalars['DateTime']['output']>;
  totalResponsesCount: Scalars['Int']['output'];
  userResponse?: Maybe<FormResponse>;
};

export type FormResponse = {
  __typename?: 'FormResponse';
  formInstanceId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  responses: Array<QuestionResponse>;
  userId: Scalars['ID']['output'];
};

export type FormResultsDisplayData = {
  __typename?: 'FormResultsDisplayData';
  checkedAnswers: Array<Scalars['ID']['output']>;
  questionsResultsDisplayData: QuestionsResultsDisplayData;
};

export type GatewayResponse = PayUResponse | StripeResponse;

export type GenerateCalendarEventsHolidays = {
  end?: InputMaybe<Scalars['String']['input']>;
  start: Scalars['String']['input'];
};

export type GenerateCalendarEventsInput = {
  cohortId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
  duration: Scalars['Int']['input'];
  holidays?: InputMaybe<Array<GenerateCalendarEventsHolidays>>;
  sessionIds: Array<Scalars['ID']['input']>;
  startDay: Scalars['Int']['input'];
  startHour: Scalars['Int']['input'];
  startMinute: Scalars['Int']['input'];
  startMonth: Scalars['Int']['input'];
  startYear: Scalars['Int']['input'];
  timeZone: Scalars['String']['input'];
  weekdays: Array<Scalars['Int']['input']>;
};

export enum GenerationType {
  Clone = 'CLONE',
  Default = 'DEFAULT',
  Original = 'ORIGINAL'
}

export type GetMentorAvailability = {
  end: Scalars['DateTime']['input'];
  start: Scalars['DateTime']['input'];
  userId: Scalars['ID']['input'];
};

export type GetUserCodelabAssignmentInput = {
  blockId: Scalars['ID']['input'];
};

export type GetUserMetricsInput = {
  metricName: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type GoogleAnalyticsConfig = {
  __typename?: 'GoogleAnalyticsConfig';
  tagManagerId: Scalars['String']['output'];
};

export type GoogleAnalyticsConfigInput = {
  tagManagerId: Scalars['String']['input'];
};

export type Grade = {
  __typename?: 'Grade';
  attempts?: Maybe<Scalars['Int']['output']>;
  cohortId?: Maybe<Scalars['ID']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  grade: Scalars['Float']['output'];
  gradesCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  response?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type Holiday = {
  __typename?: 'Holiday';
  end: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
};

export type HolidayInput = {
  end: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
};

export type InteractiveContent = {
  __typename?: 'InteractiveContent';
  assignment?: Maybe<Assignment>;
  codeRunnerGradePercentage?: Maybe<Scalars['Int']['output']>;
  codeRunnerTests?: Maybe<Array<CodeRunnerTest>>;
  codeRunnerType?: Maybe<Scalars['String']['output']>;
  /** @deprecated Will be removed in future versions */
  coinsToAward?: Maybe<Scalars['Int']['output']>;
  contentType: InteractiveContentType;
  courseId: Scalars['ID']['output'];
  courseName?: Maybe<Array<TranslatedField>>;
  defaultDeliverableContent?: Maybe<Scalars['String']['output']>;
  deliverableGradingMethod?: Maybe<DeliverableGradingMethod>;
  deliverableInstance?: Maybe<DeliverableInstance>;
  description?: Maybe<Array<TranslatedField>>;
  formId?: Maybe<Scalars['ID']['output']>;
  grade?: Maybe<Grade>;
  hiddenDeliverableContent?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  maxAttempts: Scalars['Int']['output'];
  module?: Maybe<Module>;
  moduleId: Scalars['ID']['output'];
  nonce: Scalars['String']['output'];
  preliminaryContent?: Maybe<Array<TranslatedField>>;
  programmingLanguages?: Maybe<Array<Scalars['String']['output']>>;
  prompt?: Maybe<Array<TranslatedField>>;
  question?: Maybe<Array<TranslatedField>>;
  rank: Scalars['String']['output'];
  session?: Maybe<Session>;
  sessionId: Scalars['ID']['output'];
  solution?: Maybe<AssignmentFile>;
  teamAssignment?: Maybe<TeamAssignment>;
  teamAssignmentSolution?: Maybe<TeamAssignmentSolution>;
  title: Array<TranslatedField>;
  userDeliverable?: Maybe<Deliverable>;
  userProgress?: Maybe<Progress>;
  video?: Maybe<Array<TranslatedField>>;
  /** @deprecated Use 'video' instead */
  videoLink?: Maybe<Scalars['String']['output']>;
};


export type InteractiveContentDeliverableInstanceArgs = {
  cohortId: Scalars['ID']['input'];
};


export type InteractiveContentGradeArgs = {
  cohortId: Scalars['ID']['input'];
};


export type InteractiveContentTeamAssignmentSolutionArgs = {
  cohortId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type InteractiveContentUserDeliverableArgs = {
  cohortId: Scalars['ID']['input'];
};


export type InteractiveContentUserProgressArgs = {
  cohortId: Scalars['ID']['input'];
};

export enum InteractiveContentType {
  AiQuestion = 'AI_QUESTION',
  Assignment = 'ASSIGNMENT',
  Deliverable = 'DELIVERABLE',
  HumanSkill = 'HUMAN_SKILL',
  Lab = 'LAB',
  Preparation = 'PREPARATION',
  Quiz = 'QUIZ',
  TeamAssignment = 'TEAM_ASSIGNMENT'
}

export type Invite = {
  __typename?: 'Invite';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  cohortId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  enrolledAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  notification?: Maybe<Notification>;
  paymentMethodType: PaymentMethod;
  updates: Array<InviteStatusUpdate>;
  user?: Maybe<PublicUser>;
};

export type InviteInput = {
  email: Scalars['String']['input'];
  paymentMethodType: PaymentMethod;
  template?: InputMaybe<Scalars['String']['input']>;
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  DocumentsAlreadySigned = 'DOCUMENTS_ALREADY_SIGNED',
  DocumentsIgnored = 'DOCUMENTS_IGNORED',
  DocumentsRejected = 'DOCUMENTS_REJECTED',
  DocumentsReminderSent = 'DOCUMENTS_REMINDER_SENT',
  DocumentsSent = 'DOCUMENTS_SENT',
  DocumentsSigned = 'DOCUMENTS_SIGNED',
  Enrolled = 'ENROLLED',
  ErrorSendingDocuments = 'ERROR_SENDING_DOCUMENTS',
  Other = 'OTHER',
  Sent = 'SENT'
}

export type InviteStatusUpdate = {
  __typename?: 'InviteStatusUpdate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  metadata?: Maybe<Scalars['JSON']['output']>;
  status: InviteStatus;
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export type Language = {
  __typename?: 'Language';
  language: Scalars['String']['output'];
  level: Scalars['String']['output'];
};

export type LanguageInput = {
  language: Scalars['String']['input'];
  level: Scalars['String']['input'];
};

export type LearningGoal = {
  __typename?: 'LearningGoal';
  /** @deprecated use goal instead */
  description?: Maybe<Scalars['String']['output']>;
  goal: Array<TranslatedField>;
};

export type LearningGoalInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  goal?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type MailgunConfig = {
  __typename?: 'MailgunConfig';
  apiKey: Scalars['String']['output'];
  domain: Scalars['String']['output'];
};

export type MailgunConfigInput = {
  apiKey: Scalars['String']['input'];
  domain: Scalars['String']['input'];
};

export type MailingConfig = {
  __typename?: 'MailingConfig';
  emailFooterContent?: Maybe<Scalars['String']['output']>;
  emailHeaderUrl?: Maybe<Scalars['String']['output']>;
};

export type MailingConfigInput = {
  emailFooterContent?: InputMaybe<Scalars['String']['input']>;
  emailHeaderUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MediaUploadInput = {
  contentType: Scalars['String']['input'];
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  filename: Scalars['String']['input'];
  mediaContext?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  variants?: InputMaybe<Array<MediaVariantInput>>;
};

export type MediaUploadResponse = {
  __typename?: 'MediaUploadResponse';
  mediaId?: Maybe<Scalars['ID']['output']>;
  mediaURL: Scalars['String']['output'];
  mediaUploadURL: Scalars['String']['output'];
};

export type MediaVariantInput = {
  contentType: Scalars['String']['input'];
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type MentorAvailability = {
  __typename?: 'MentorAvailability';
  end: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  start: Scalars['DateTime']['output'];
  status: MentorAvailabilityStatus;
  userId: Scalars['ID']['output'];
};

export enum MentorAvailabilityStatus {
  Available = 'AVAILABLE',
  ClassScheduled = 'CLASS_SCHEDULED'
}

export type Metric = {
  __typename?: 'Metric';
  data: Array<MetricSeries>;
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MetricSeries = {
  __typename?: 'MetricSeries';
  group?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  series: Array<MetricValue>;
};

export type MetricValue = {
  __typename?: 'MetricValue';
  date?: Maybe<Scalars['DateTime']['output']>;
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  valueType?: Maybe<Scalars['String']['output']>;
};

export type MetricsSearchInput = {
  cohortIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  courseIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  formInstanceId?: InputMaybe<Scalars['ID']['input']>;
  metricName: Scalars['String']['input'];
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['ID']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Module = {
  __typename?: 'Module';
  badges?: Maybe<Array<Badge>>;
  /** @deprecated No longer supported, will be removed in future versions */
  code?: Maybe<Scalars['String']['output']>;
  course?: Maybe<Course>;
  courseId: Scalars['ID']['output'];
  description?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Array<TranslatedField>>;
  prerequisites?: Maybe<Array<Module>>;
  rank: Scalars['String']['output'];
  sessionCount: Scalars['Int']['output'];
  sessions: Array<Session>;
  userDeliverables: Array<Deliverable>;
  userPrerequisitesProgress: Scalars['Int']['output'];
  userProgress?: Maybe<Progress>;
};


export type ModuleUserDeliverablesArgs = {
  cohortId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ModuleUserPrerequisitesProgressArgs = {
  cohortId: Scalars['ID']['input'];
  excludedActivityTypes?: InputMaybe<Array<ActivityType>>;
};


export type ModuleUserProgressArgs = {
  cohortId: Scalars['ID']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCalendarEventResponsible: CalendarEvent;
  addOrganizationOwner: Organization;
  addOrganizationUser: Organization;
  addResponsibleComment: CalendarEvent;
  addUserBadge: Badge;
  addUserToCommunity: Scalars['String']['output'];
  aiQuestionFeedback?: Maybe<FlowsGrade>;
  assignContentDeliverables: Array<Deliverable>;
  assistantReply: VirtualAssistantResponse;
  autoAssignEvents?: Maybe<Scalars['String']['output']>;
  awardCertificate: Array<Certificate>;
  awardCoins: Array<Coins>;
  blockUser: Scalars['ID']['output'];
  cloneAndSyncCourse: Scalars['ID']['output'];
  cloneAndSyncProgram: Scalars['ID']['output'];
  completeAssignment?: Maybe<InteractiveContent>;
  completeCodelab: CodelabResponse;
  contentAiQuestionFeedback?: Maybe<Grade>;
  copyForm: Form;
  copyModule?: Maybe<Module>;
  copySession?: Maybe<Session>;
  createAnswer: AnswerOption;
  createApiKey: ApiKey;
  createAttendance: AttendanceResponse;
  createBadge: Badge;
  createBlock: Block;
  createCalendarEvent: CalendarEvent;
  createCertificate: Certificate;
  createChangeHistory: ChangeHistory;
  createCodelab: Codelab;
  createCodelabAssignment: CodelabAssignment;
  createCohort: Cohort;
  createCommunity: Community;
  createConnection: Connection;
  createCourse: Course;
  createDeliverable: Deliverable;
  createDiscordChannel?: Maybe<Scalars['String']['output']>;
  createFeedback: Scalars['ID']['output'];
  createFlow: Flow;
  createFlowsProgress: FlowsProgress;
  createForm: Form;
  createFormInstance: FormInstance;
  createFormQuestion: Question;
  createFormQuestionAnswerGroup: Question;
  createFormResponse: FormResponse;
  createGrade: Grade;
  createIndividualAssignment?: Maybe<CodelabResponse>;
  createInteractiveContent?: Maybe<InteractiveContent>;
  createMediaUploadURL: MediaUploadResponse;
  createMentorAvailability: MentorAvailability;
  createModule: Module;
  createNexusTeam: NexusTeam;
  createNode: Node;
  createNotification: Notification;
  createOrder: OrderResponse;
  createOrganization: Organization;
  createPlatformFeedback: Scalars['ID']['output'];
  createProgram: Program;
  createPromoCode: PromoCode;
  createPublicSchool: School;
  createQuestion: QuizQuestion;
  createQuiz: Quiz;
  createQuizResponse: QuizResponse;
  createSecondCheckNotification: Notification;
  createSession: Session;
  createSurvey: Survey;
  createSurveyResponse: SurveyResponse;
  createTeam: Team;
  createTeamAssignmentSolution?: Maybe<TeamAssignmentSolution>;
  createTeamAssignments?: Maybe<CodelabResponse>;
  createUser: User;
  createWorkReport: WorkReport;
  deleteAnswer: Scalars['String']['output'];
  deleteApiKey: Scalars['ID']['output'];
  deleteBadge: Scalars['ID']['output'];
  deleteBlock: Scalars['ID']['output'];
  deleteCalendarEvent: Scalars['ID']['output'];
  deleteCertificate: Scalars['ID']['output'];
  deleteCodelab: Scalars['String']['output'];
  deleteCodelabAssignment: Scalars['String']['output'];
  deleteConnection: Scalars['ID']['output'];
  deleteFlow: Scalars['ID']['output'];
  deleteForm: Scalars['ID']['output'];
  deleteFormInstanceById: Scalars['ID']['output'];
  deleteFormQuestion: Scalars['ID']['output'];
  deleteFormQuestionAnswerGroup: Question;
  deleteFormResponse: Scalars['ID']['output'];
  deleteGrade: Scalars['ID']['output'];
  deleteInteractiveContent: Scalars['ID']['output'];
  deleteMentorAvailability: Scalars['ID']['output'];
  deleteModule: Scalars['ID']['output'];
  deleteNode: Scalars['ID']['output'];
  deleteNotification: Scalars['ID']['output'];
  deleteOrganization: Scalars['ID']['output'];
  deleteProgress: Scalars['ID']['output'];
  deleteQuestion: Scalars['String']['output'];
  deleteQuiz: Scalars['String']['output'];
  deleteSession: Scalars['ID']['output'];
  deleteUser: Scalars['ID']['output'];
  disenrollUser: Scalars['ID']['output'];
  enrollMe: Scalars['ID']['output'];
  enrollUser: Scalars['ID']['output'];
  generateCalendarEvents: Array<CalendarEvent>;
  joinCommunity: Scalars['String']['output'];
  leaveTeam: Scalars['ID']['output'];
  markNotificationsAsRead: Scalars['Int']['output'];
  notifyUsers: Array<Maybe<Notification>>;
  preRegisterUser: Scalars['ID']['output'];
  processDeliverables: Scalars['String']['output'];
  processMediaVariants: Scalars['String']['output'];
  processNotifications: Scalars['String']['output'];
  recalculateProgress: BatchProcess;
  removeCalendarEventResponsible: CalendarEvent;
  removeDeliverableGrade: Deliverable;
  removeOrganizationOwner: Organization;
  removeOrganizationUser: Organization;
  removeTeamAssignmentSolution: Scalars['ID']['output'];
  removeUserBadge: Badge;
  removeUserFromCommunity: Scalars['String']['output'];
  resumeSubscription: Scalars['String']['output'];
  runCode: RemoteCodeRunnerResponse;
  sendCohortInvites: Scalars['String']['output'];
  sendInvites: Array<Maybe<Scalars['ID']['output']>>;
  sendPendingNotifications: Array<Scalars['ID']['output']>;
  setDeliverableInstanceDueDate: DeliverableInstance;
  setExcludeFromDeliverables: Enrollment;
  setProgress: Progress;
  submitSolution?: Maybe<TeamAssignmentSolution>;
  subscribeMe: SubscribeMeResponse;
  subscribeSchool: Scalars['String']['output'];
  swapBlocksOrder: Array<Block>;
  swapInteractiveContentRank: Array<InteractiveContent>;
  swapModuleRank: Array<Module>;
  swapSessionRank: Array<Session>;
  syncCourse: Course;
  syncProgram: Program;
  syncProgramEnrollments: Cohort;
  track: Scalars['String']['output'];
  trackContentEngagement: Scalars['String']['output'];
  unblockUser: Scalars['ID']['output'];
  unsubscribeMe: Scalars['String']['output'];
  updateAttendance: Attendance;
  updateBadge: Badge;
  updateBlock: Block;
  updateCalendarEvent: CalendarEvent;
  updateCertificate: Certificate;
  updateCodelab: Codelab;
  updateCodelabAssignment: CodelabAssignment;
  updateCohort: Cohort;
  updateCommunity: Community;
  updateConnection: Connection;
  updateCourse: Course;
  updateFlow: Flow;
  updateForm: Form;
  updateFormInstance: FormInstance;
  updateFormQuestion: Question;
  updateInteractiveContent?: Maybe<InteractiveContent>;
  updateMentorAvailability: MentorAvailability;
  updateModule: Module;
  updateMyProfile: UserProfile;
  updateNode: Node;
  updateOrganization: Organization;
  updateProgram: Program;
  updateQuestion: QuizQuestion;
  updateQuiz: Quiz;
  updateSchool: School;
  updateSession: Session;
  updateTeam: Team;
  updateUser: User;
  validatePromoCode?: Maybe<PromoCodeValidateResponse>;
};


export type MutationAddCalendarEventResponsibleArgs = {
  calendarEventId: Scalars['ID']['input'];
  role: ResponsibleRole;
  userId: Scalars['ID']['input'];
};


export type MutationAddOrganizationOwnerArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddOrganizationUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddResponsibleCommentArgs = {
  calendarEventId: Scalars['ID']['input'];
  comments: Scalars['String']['input'];
};


export type MutationAddUserBadgeArgs = {
  addUserBadgeInput: AddUserBadgeInput;
};


export type MutationAddUserToCommunityArgs = {
  communityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAiQuestionFeedbackArgs = {
  aiQuestionFeedbackInput: AiQuestionFeedbackInput;
};


export type MutationAssignContentDeliverablesArgs = {
  assignDeliverablesInput: AssignDeliverablesInput;
};


export type MutationAssistantReplyArgs = {
  context: AssistantReplyContextInput;
  text: Scalars['String']['input'];
};


export type MutationAwardCertificateArgs = {
  awardCertificateInput: AwardCertificateInput;
};


export type MutationAwardCoinsArgs = {
  awardCoinsInput: AwardCoinsInput;
};


export type MutationBlockUserArgs = {
  blockUserInput: DisenrollUserInput;
};


export type MutationCloneAndSyncCourseArgs = {
  originalId: Scalars['ID']['input'];
  targetSchoolId: Scalars['ID']['input'];
};


export type MutationCloneAndSyncProgramArgs = {
  originalId: Scalars['ID']['input'];
  targetSchoolId: Scalars['ID']['input'];
};


export type MutationCompleteAssignmentArgs = {
  cohortId: Scalars['ID']['input'];
  interactiveContentId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};


export type MutationCompleteCodelabArgs = {
  completeCodelabInput: CompleteCodelabInput;
};


export type MutationContentAiQuestionFeedbackArgs = {
  aiQuestionFeedbackInput: AiQuestionFeedbackInput;
  cohortId: Scalars['ID']['input'];
};


export type MutationCopyFormArgs = {
  courseId: Scalars['ID']['input'];
  formId: Scalars['ID']['input'];
};


export type MutationCopyModuleArgs = {
  courseId: Scalars['ID']['input'];
  moduleId: Scalars['ID']['input'];
};


export type MutationCopySessionArgs = {
  moduleId: Scalars['ID']['input'];
  sessionId: Scalars['ID']['input'];
};


export type MutationCreateAnswerArgs = {
  createAnswerInput: AnswerOptionInput;
  questionId: Scalars['ID']['input'];
};


export type MutationCreateApiKeyArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateAttendanceArgs = {
  calendarEventId: Scalars['ID']['input'];
};


export type MutationCreateBadgeArgs = {
  createBadgeInput: CreateBadgeInput;
};


export type MutationCreateBlockArgs = {
  createBlockInput: CreateBlockInput;
};


export type MutationCreateCalendarEventArgs = {
  createCalendarEventInput: CreateCalendarEventInput;
};


export type MutationCreateCertificateArgs = {
  createCertificateInput: CreateCertificateInput;
};


export type MutationCreateChangeHistoryArgs = {
  createChangeHistoryInput: CreateChangeHistoryInput;
};


export type MutationCreateCodelabArgs = {
  createCodelabInput: CreateCodelabInput;
};


export type MutationCreateCodelabAssignmentArgs = {
  createCodelabAssignmentInput: CreateCodelabAssignmentInput;
};


export type MutationCreateCohortArgs = {
  createCohortInput: CreateCohortInput;
};


export type MutationCreateCommunityArgs = {
  createCommunityInput: CreateCommunityInput;
};


export type MutationCreateConnectionArgs = {
  createConnectionInput: CreateConnectionInput;
};


export type MutationCreateCourseArgs = {
  createCourseInput: CreateCourseInput;
};


export type MutationCreateDeliverableArgs = {
  createDeliverableInput: CreateDeliverableInput;
};


export type MutationCreateDiscordChannelArgs = {
  createDiscordChannelInput: CreateDiscordChannelInput;
};


export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};


export type MutationCreateFlowArgs = {
  createFlowInput: CreateFlowInput;
};


export type MutationCreateFlowsProgressArgs = {
  createFlowsProgressInput: CreateFlowsProgressInput;
};


export type MutationCreateFormArgs = {
  createFormInput: CreateFormInput;
};


export type MutationCreateFormInstanceArgs = {
  createFormInstanceInput: CreateFormInstanceInput;
};


export type MutationCreateFormQuestionArgs = {
  createQuestionInput: CreateQuestionInput;
  formId: Scalars['ID']['input'];
};


export type MutationCreateFormQuestionAnswerGroupArgs = {
  formId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};


export type MutationCreateFormResponseArgs = {
  createFormResponseInput: CreateFormResponseInput;
};


export type MutationCreateGradeArgs = {
  createGradeInput: CreateGradeInput;
};


export type MutationCreateIndividualAssignmentArgs = {
  codelabId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
};


export type MutationCreateInteractiveContentArgs = {
  createInteractiveContentInput: CreateInteractiveContentInput;
};


export type MutationCreateMediaUploadUrlArgs = {
  mediaUploadInput: MediaUploadInput;
};


export type MutationCreateMentorAvailabilityArgs = {
  createMentorAvailabilityInput: CreateMentorAvailability;
};


export type MutationCreateModuleArgs = {
  createModuleInput: CreateModuleInput;
};


export type MutationCreateNexusTeamArgs = {
  createNexusTeamInput: CreateNexusTeamInput;
};


export type MutationCreateNodeArgs = {
  createNodeInput: CreateNodeInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreateOrganizationArgs = {
  createOrganizationInput: CreateOrganizationInput;
};


export type MutationCreatePlatformFeedbackArgs = {
  feedbackInput: PlatformFeedbackInput;
};


export type MutationCreateProgramArgs = {
  createProgramInput: CreateProgramInput;
};


export type MutationCreatePromoCodeArgs = {
  createPromoCodeInput: CreatePromoCodeInput;
};


export type MutationCreatePublicSchoolArgs = {
  createSchoolInput: CreateSchoolInput;
};


export type MutationCreateQuestionArgs = {
  createQuestionInput: CreateQuizQuestionInput;
  quizId: Scalars['ID']['input'];
};


export type MutationCreateQuizArgs = {
  createQuizInput: CreateQuizInput;
};


export type MutationCreateQuizResponseArgs = {
  createQuizResponseInput: CreateQuizResponseInput;
};


export type MutationCreateSecondCheckNotificationArgs = {
  createSecondCheckNotificationInput: CreateSecondCheckNotificationInput;
};


export type MutationCreateSessionArgs = {
  createSessionInput: CreateSessionInput;
};


export type MutationCreateSurveyArgs = {
  createSurveyInput: CreateSurveyInput;
};


export type MutationCreateSurveyResponseArgs = {
  createSurveyResponseInput: CreateSurveyResponseInput;
};


export type MutationCreateTeamArgs = {
  createTeamInput: CreateTeamInput;
};


export type MutationCreateTeamAssignmentSolutionArgs = {
  cohortId: Scalars['ID']['input'];
  interactiveContentId: Scalars['ID']['input'];
  teamId: Scalars['ID']['input'];
};


export type MutationCreateTeamAssignmentsArgs = {
  createTeamAssignmentsInput: CreateTeamAssignmentsInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationCreateWorkReportArgs = {
  createWorkReportInput: CreateWorkReportInput;
};


export type MutationDeleteAnswerArgs = {
  answerId: Scalars['ID']['input'];
};


export type MutationDeleteApiKeyArgs = {
  apiKeyId: Scalars['ID']['input'];
};


export type MutationDeleteBadgeArgs = {
  badgeId: Scalars['ID']['input'];
};


export type MutationDeleteBlockArgs = {
  blockId: Scalars['ID']['input'];
};


export type MutationDeleteCalendarEventArgs = {
  calendarEventId: Scalars['ID']['input'];
};


export type MutationDeleteCertificateArgs = {
  certificateId: Scalars['ID']['input'];
};


export type MutationDeleteCodelabArgs = {
  codelabId: Scalars['ID']['input'];
};


export type MutationDeleteCodelabAssignmentArgs = {
  codelabAssignmentId: Scalars['ID']['input'];
};


export type MutationDeleteConnectionArgs = {
  connectionId: Scalars['ID']['input'];
};


export type MutationDeleteFlowArgs = {
  flowId: Scalars['ID']['input'];
};


export type MutationDeleteFormArgs = {
  formId: Scalars['ID']['input'];
};


export type MutationDeleteFormInstanceByIdArgs = {
  formInstanceId: Scalars['ID']['input'];
};


export type MutationDeleteFormQuestionArgs = {
  formId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};


export type MutationDeleteFormQuestionAnswerGroupArgs = {
  formId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};


export type MutationDeleteFormResponseArgs = {
  deleteFormResponseInput: DeleteFormResponseInput;
};


export type MutationDeleteGradeArgs = {
  gradeId: Scalars['ID']['input'];
};


export type MutationDeleteInteractiveContentArgs = {
  interactiveContentId: Scalars['ID']['input'];
};


export type MutationDeleteMentorAvailabilityArgs = {
  mentorAvailabilityId: Scalars['ID']['input'];
};


export type MutationDeleteModuleArgs = {
  moduleId: Scalars['ID']['input'];
};


export type MutationDeleteNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


export type MutationDeleteNotificationArgs = {
  recipientId: Scalars['ID']['input'];
  recipientType: EntityName;
};


export type MutationDeleteOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteProgressArgs = {
  progressId: Scalars['ID']['input'];
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['ID']['input'];
};


export type MutationDeleteQuizArgs = {
  quizId: Scalars['ID']['input'];
};


export type MutationDeleteSessionArgs = {
  sessionId: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDisenrollUserArgs = {
  disenrollUserInput: DisenrollUserInput;
};


export type MutationEnrollMeArgs = {
  cohortId: Scalars['ID']['input'];
  referrerCode?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnrollUserArgs = {
  enrollUserInput: EnrollUserInput;
};


export type MutationGenerateCalendarEventsArgs = {
  generateCalendarEventsInput: GenerateCalendarEventsInput;
};


export type MutationJoinCommunityArgs = {
  communityId: Scalars['ID']['input'];
};


export type MutationLeaveTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type MutationMarkNotificationsAsReadArgs = {
  notificationIds: Array<Scalars['ID']['input']>;
};


export type MutationNotifyUsersArgs = {
  createNotification: CreateNotificationInput;
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationPreRegisterUserArgs = {
  preRegisterUserInput: PreRegisterUserInput;
};


export type MutationProcessMediaVariantsArgs = {
  mediaId: Scalars['ID']['input'];
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationRecalculateProgressArgs = {
  cohortId: Scalars['ID']['input'];
  courseId: Scalars['ID']['input'];
};


export type MutationRemoveCalendarEventResponsibleArgs = {
  calendarEventId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveDeliverableGradeArgs = {
  deliverableId: Scalars['ID']['input'];
};


export type MutationRemoveOrganizationOwnerArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveOrganizationUserArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveTeamAssignmentSolutionArgs = {
  assignmentSolutionId: Scalars['ID']['input'];
};


export type MutationRemoveUserBadgeArgs = {
  removeUserBadgeInput: RemoveUserBadgeInput;
};


export type MutationRemoveUserFromCommunityArgs = {
  communityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationResumeSubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type MutationRunCodeArgs = {
  code: Scalars['String']['input'];
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<Array<Scalars['String']['input']>>;
  language: Scalars['String']['input'];
};


export type MutationSendCohortInvitesArgs = {
  sendCohortInvitesInput: SendCohortInvitesInput;
};


export type MutationSendInvitesArgs = {
  sendInvitesInput: SendInvitesInput;
};


export type MutationSetDeliverableInstanceDueDateArgs = {
  cohortId: Scalars['ID']['input'];
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  interactiveContentId: Scalars['ID']['input'];
};


export type MutationSetExcludeFromDeliverablesArgs = {
  enrollmentId: Scalars['ID']['input'];
  exclude: Scalars['Boolean']['input'];
};


export type MutationSetProgressArgs = {
  createProgressInput: CreateProgressInput;
};


export type MutationSubmitSolutionArgs = {
  teamAssignmentUserSolutionInput: TeamAssignmentUserSolutionInput;
};


export type MutationSubscribeMeArgs = {
  subscribeMeInput: SubscribeMeInput;
};


export type MutationSubscribeSchoolArgs = {
  subscribeSchoolInput: SubscribeSchoolInput;
};


export type MutationSwapBlocksOrderArgs = {
  firstBlockId: Scalars['ID']['input'];
  secondBlockId: Scalars['ID']['input'];
};


export type MutationSwapInteractiveContentRankArgs = {
  firstInteractiveContentId: Scalars['ID']['input'];
  secondInteractiveContentId: Scalars['ID']['input'];
};


export type MutationSwapModuleRankArgs = {
  firstModuleId: Scalars['ID']['input'];
  secondModuleId: Scalars['ID']['input'];
};


export type MutationSwapSessionRankArgs = {
  firstSessionId: Scalars['ID']['input'];
  secondSessionId: Scalars['ID']['input'];
};


export type MutationSyncCourseArgs = {
  courseId: Scalars['ID']['input'];
};


export type MutationSyncProgramArgs = {
  programId: Scalars['ID']['input'];
};


export type MutationSyncProgramEnrollmentsArgs = {
  cohortId: Scalars['ID']['input'];
};


export type MutationTrackArgs = {
  trackEventInput: TrackEventInput;
};


export type MutationTrackContentEngagementArgs = {
  contentEngagementTrackingInput: ContentEngagementTrackingInput;
};


export type MutationUnblockUserArgs = {
  unblockUserInput: DisenrollUserInput;
};


export type MutationUnsubscribeMeArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type MutationUpdateAttendanceArgs = {
  calendarEventId: Scalars['ID']['input'];
};


export type MutationUpdateBadgeArgs = {
  updateBadgeInput: UpdateBadgeInput;
};


export type MutationUpdateBlockArgs = {
  updateBlockInput: UpdateBlockInput;
};


export type MutationUpdateCalendarEventArgs = {
  updateCalendarEventInput: UpdateCalendarEventInput;
};


export type MutationUpdateCertificateArgs = {
  updateCertificateInput: UpdateCertificateInput;
};


export type MutationUpdateCodelabArgs = {
  updateCodelabInput: UpdateCodelabInput;
};


export type MutationUpdateCodelabAssignmentArgs = {
  updateCodelabAssignmentInput: UpdateCodelabAssignmentInput;
};


export type MutationUpdateCohortArgs = {
  updateCohortInput: UpdateCohortInput;
};


export type MutationUpdateCommunityArgs = {
  updateCommunityInput: UpdateCommunityInput;
};


export type MutationUpdateConnectionArgs = {
  updateConnectionInput: UpdateConnectionInput;
};


export type MutationUpdateCourseArgs = {
  updateCourseInput: UpdateCourseInput;
};


export type MutationUpdateFlowArgs = {
  updateFlowInput: UpdateFlowInput;
};


export type MutationUpdateFormArgs = {
  updateFormInput: UpdateFormInput;
};


export type MutationUpdateFormInstanceArgs = {
  updateFormInstanceInput: UpdateFormInstanceInput;
};


export type MutationUpdateFormQuestionArgs = {
  formId: Scalars['ID']['input'];
  updateQuestionInput: UpdateQuestionInput;
};


export type MutationUpdateInteractiveContentArgs = {
  updateInteractiveContentInput: UpdateInteractiveContentInput;
};


export type MutationUpdateMentorAvailabilityArgs = {
  updateMentorAvailabilityInput: UpdateMentorAvailability;
};


export type MutationUpdateModuleArgs = {
  updateModuleInput: UpdateModuleInput;
};


export type MutationUpdateMyProfileArgs = {
  updateUserProfileInput: UpdateUserProfileInput;
};


export type MutationUpdateNodeArgs = {
  updateNodeInput: UpdateNodeInput;
};


export type MutationUpdateOrganizationArgs = {
  updateOrganizationInput: UpdateOrganizationInput;
};


export type MutationUpdateProgramArgs = {
  updateProgramInput: UpdateProgramInput;
};


export type MutationUpdateQuestionArgs = {
  updateQuestionInput: UpdateQuizQuestionInput;
};


export type MutationUpdateQuizArgs = {
  updateQuizInput: UpdateQuizInput;
};


export type MutationUpdateSchoolArgs = {
  updateSchoolInput: UpdateSchoolInput;
};


export type MutationUpdateSessionArgs = {
  updateSessionInput: UpdateSessionInput;
};


export type MutationUpdateTeamArgs = {
  updateTeamInput: UpdateTeamInput;
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationValidatePromoCodeArgs = {
  cohortIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  promoCode: Scalars['String']['input'];
};

export type NexusTeam = {
  __typename?: 'NexusTeam';
  flowId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['String']['output'];
  individualOwner?: Maybe<Scalars['ID']['output']>;
  memberIds: Array<Scalars['ID']['output']>;
  members: Array<PublicUser>;
  name: Scalars['String']['output'];
  schoolId: Scalars['String']['output'];
};

export type Node = {
  __typename?: 'Node';
  blocks: Array<Block>;
  data?: Maybe<Scalars['JSON']['output']>;
  description: Array<TranslatedField>;
  flowId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Array<TranslatedField>;
  nodeType: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  position: NodePosition;
  size?: Maybe<NodeSize>;
  userBlocksProgress: Array<FlowsProgress>;
  userHasCompletedConnectedNodes: Scalars['Boolean']['output'];
};


export type NodeBlocksArgs = {
  entitySearchInput?: InputMaybe<EntitySearchInput>;
};

export type NodePosition = {
  __typename?: 'NodePosition';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type NodePositionInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type NodeSize = {
  __typename?: 'NodeSize';
  h: Scalars['Float']['output'];
  w: Scalars['Float']['output'];
};

export type NodeSizeInput = {
  h: Scalars['Float']['input'];
  w: Scalars['Float']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  channel: NotificationChannel;
  channelConfig: ChannelConfig;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  message: Array<TranslatedField>;
  notificationTime: Scalars['DateTime']['output'];
  readAt?: Maybe<Scalars['DateTime']['output']>;
  recipientId: Scalars['ID']['output'];
  recipientType: Scalars['String']['output'];
  status: NotificationStatus;
};

export enum NotificationChannel {
  Dashboard = 'DASHBOARD',
  Discord = 'DISCORD',
  Email = 'EMAIL',
  Slack = 'SLACK'
}

export enum NotificationStatus {
  Created = 'CREATED',
  Failed = 'FAILED',
  Read = 'READ',
  Sent = 'SENT'
}

export type OptionSurvey = {
  __typename?: 'OptionSurvey';
  id: Scalars['ID']['output'];
  isOther: Scalars['Boolean']['output'];
  title: Array<TranslatedField>;
};

export type OptionSurveyInput = {
  isOther?: InputMaybe<Scalars['Boolean']['input']>;
  title: Array<TranslatedFieldInput>;
};

export type Order = {
  __typename?: 'Order';
  currency: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  items: Array<OrderItem>;
  paymentMethodType: PaymentMethod;
  referrerCode?: Maybe<Scalars['String']['output']>;
  shortId: Scalars['String']['output'];
  statusUpdates: Array<OrderStatusUpdate>;
  total: Price;
  user: User;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  cohort?: Maybe<Cohort>;
  course?: Maybe<Course>;
  id: Scalars['ID']['output'];
  publicCohort?: Maybe<CourseCohortPrice>;
  unitPrice: Price;
};

export type OrderItemInput = {
  cohortId: Scalars['ID']['input'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  gatewayResponse?: Maybe<GatewayResponse>;
  order?: Maybe<Order>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Unkown = 'UNKOWN'
}

export type OrderStatusUpdate = {
  __typename?: 'OrderStatusUpdate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  status: OrderStatus;
};

export type Organization = PublicOrganization & {
  __typename?: 'Organization';
  cohorts: Array<Cohort>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owners: Array<User>;
  users: Array<User>;
};


export type OrganizationCohortsArgs = {
  searchOrganizationCohorts?: InputMaybe<EntitySearchInput>;
};


export type OrganizationUsersArgs = {
  userSearchInput: EntitySearchInput;
};

export enum OrganizationRole {
  Owner = 'OWNER',
  Sponsor = 'SPONSOR'
}

export type PayUConfig = {
  __typename?: 'PayUConfig';
  accountId: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  merchantId: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  useSandbox: Scalars['Boolean']['output'];
};

export type PayUConfigInput = {
  accountId: Scalars['String']['input'];
  apiKey: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
  mode: Scalars['String']['input'];
  useSandbox: Scalars['Boolean']['input'];
};

export type PayUResponse = {
  __typename?: 'PayUResponse';
  accountId: Scalars['String']['output'];
  confirmationUrl: Scalars['String']['output'];
  merchantId: Scalars['String']['output'];
  mode: Scalars['String']['output'];
  responseUrl: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  webCheckoutUrl: Scalars['String']['output'];
};

export enum PaymentMethod {
  AdaCoins = 'ADA_COINS',
  Free = 'FREE',
  Installments = 'INSTALLMENTS',
  PayU = 'PAY_U',
  PayWhenEmployed = 'PAY_WHEN_EMPLOYED',
  Stripe = 'STRIPE'
}

export type PlatformFeedback = {
  __typename?: 'PlatformFeedback';
  category: Scalars['String']['output'];
  feedback: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  pageUrl?: Maybe<Scalars['String']['output']>;
};

export type PlatformFeedbackInput = {
  category: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
  pageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PreRegisterUserInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roles: Array<UserRole>;
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String']['output'];
  value: Scalars['Float']['output'];
  valueInCents: Scalars['Int']['output'];
};

export type PriceInput = {
  currency: Scalars['String']['input'];
  valueInCents: Scalars['Int']['input'];
};

export type Program = {
  __typename?: 'Program';
  badges?: Maybe<Array<Badge>>;
  badgesCount: Scalars['Int']['output'];
  code: Scalars['String']['output'];
  cohorts: Array<Cohort>;
  courses: Array<Course>;
  description?: Maybe<Array<TranslatedField>>;
  generationType: GenerationType;
  id: Scalars['ID']['output'];
  /** @deprecated Use 'programBadges' instead */
  imageUrl?: Maybe<Scalars['String']['output']>;
  items: Array<ProgramItem>;
  name: Scalars['String']['output'];
  programBadges?: Maybe<Array<Badge>>;
  schoolId: Scalars['ID']['output'];
  status: CourseStatus;
  userBadgesCount: Scalars['Int']['output'];
  visibility: CourseVisibility;
};


export type ProgramBadgesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type ProgramCohortsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<CohortVisibility>;
};


export type ProgramProgramBadgesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type ProgramDetails = {
  __typename?: 'ProgramDetails';
  badges?: Maybe<Array<PublicBadge>>;
  description?: Maybe<Array<TranslatedField>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  programId: Scalars['ID']['output'];
};

export type ProgramItem = {
  __typename?: 'ProgramItem';
  content?: Maybe<ProgramItemContent>;
  id: Scalars['ID']['output'];
  parentIds: Array<Scalars['ID']['output']>;
};

export type ProgramItemContent = Course;

export type ProgramItemInput = {
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  parentIds: Array<Scalars['ID']['input']>;
};

export type Progress = {
  __typename?: 'Progress';
  cohortId?: Maybe<Scalars['ID']['output']>;
  entity: EntityName;
  entityId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  progress: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type PromoCode = {
  __typename?: 'PromoCode';
  code: Scalars['String']['output'];
  cohortIds?: Maybe<Array<Scalars['ID']['output']>>;
  discount: Scalars['Int']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  ownerId?: Maybe<Scalars['ID']['output']>;
  schoolId: Scalars['ID']['output'];
};

export type PromoCodeUsage = {
  __typename?: 'PromoCodeUsage';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  promoCode: PromoCode;
  user: User;
};

export type PromoCodeValidateResponse = {
  __typename?: 'PromoCodeValidateResponse';
  error?: Maybe<Scalars['String']['output']>;
  promoCode?: Maybe<PromoCode>;
};

export type PublicBadge = {
  __typename?: 'PublicBadge';
  description: Array<TranslatedField>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Array<TranslatedField>;
};

export type PublicOrganization = {
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PublicOrganizationWithRole = {
  __typename?: 'PublicOrganizationWithRole';
  organization: PublicOrganization;
  role: OrganizationRole;
};

export type PublicOrganizationWithRoleInput = {
  organizationId: Scalars['ID']['input'];
  role: OrganizationRole;
};

export type PublicSchool = BaseSchool & {
  __typename?: 'PublicSchool';
  allowedLanguages?: Maybe<Array<Scalars['String']['output']>>;
  conductCodeUrl?: Maybe<Scalars['String']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  frontendFeatures: Array<Scalars['String']['output']>;
  googleAnalytics?: Maybe<GoogleAnalyticsConfig>;
  gradingPolicyUrl?: Maybe<Scalars['String']['output']>;
  headerAccent?: Maybe<Scalars['String']['output']>;
  headerColor?: Maybe<Scalars['String']['output']>;
  heroBackground?: Maybe<Scalars['String']['output']>;
  heroImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  linkedInId?: Maybe<Scalars['String']['output']>;
  loginLogoUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  onboardingFlowId?: Maybe<Scalars['ID']['output']>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  privacyUrl?: Maybe<Scalars['String']['output']>;
  termsUrl?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Theme>;
};

export type PublicSubscriptionProduct = {
  __typename?: 'PublicSubscriptionProduct';
  features: Array<Array<TranslatedField>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  prices: Array<SubscriptionPrice>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  gitHubUsername?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  profile?: Maybe<PublicUserProfile>;
  referrerCode?: Maybe<Scalars['String']['output']>;
  uploadedAvatarUrl?: Maybe<Scalars['String']['output']>;
};

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  avatarImageURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  badge: Badge;
  block?: Maybe<Block>;
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventsByDateRange: Array<CalendarEvent>;
  calendarEventsByResponsible: Array<CalendarEvent>;
  calendarEventsBySession: Array<CalendarEvent>;
  certificate: Certificate;
  checkApiKey: Scalars['Boolean']['output'];
  codelab?: Maybe<Codelab>;
  codelabAssignment?: Maybe<CodelabAssignment>;
  codelabAssignmentForUser?: Maybe<CodelabAssignment>;
  codelabResponseTeamProgress: CodelabResponse;
  cohort?: Maybe<Cohort>;
  /** @deprecated Use searchCohorts instead */
  cohorts: Array<Cohort>;
  community?: Maybe<Community>;
  course?: Maybe<Course>;
  courseCohortPrice?: Maybe<CourseCohortPrice>;
  courses: Array<Course>;
  currentSchool: PublicSchool;
  deliverableInstances: Array<DeliverableInstance>;
  feedback: Array<Feedback>;
  flow?: Maybe<Flow>;
  form: Form;
  formInstance: FormInstance;
  formInstancesByEntity: Array<FormInstance>;
  formResponse?: Maybe<FormResultsDisplayData>;
  forms: Array<Form>;
  getAllowedPaymentMethods: Array<PaymentMethod>;
  getAssignmentCodeForUser: CodelabAssignment;
  getCodelabSolutions: CodelabAssignment;
  getEntityChangeHistory: Array<ChangeHistory>;
  getGradedDeliverablesHours: Array<WorkReport>;
  getLiveSessionsHours: TotalRoleHours;
  getMentorAvailability?: Maybe<Array<MentorAvailability>>;
  getNextSurveyId?: Maybe<Scalars['ID']['output']>;
  getUserCodelabAssignment?: Maybe<UserCodelabAssignment>;
  getUserMetrics: Array<Metric>;
  getWorkReportHours: Array<WorkReport>;
  grade?: Maybe<Grade>;
  interactiveContent?: Maybe<InteractiveContent>;
  interactiveContentBySession: Array<InteractiveContent>;
  me?: Maybe<User>;
  mentor?: Maybe<User>;
  /** @deprecated Use searchUsers instead */
  mentors: Array<User>;
  metrics: Array<Metric>;
  module?: Maybe<Module>;
  moduleList: Array<Maybe<Module>>;
  nexusTeam?: Maybe<NexusTeam>;
  node?: Maybe<Node>;
  organization: Organization;
  organizations: Array<Organization>;
  platformFeedback: Array<PlatformFeedback>;
  products: Array<PublicSubscriptionProduct>;
  program?: Maybe<Program>;
  publicUser?: Maybe<PublicUser>;
  question?: Maybe<QuizQuestion>;
  quiz?: Maybe<Quiz>;
  quizResponse?: Maybe<QuizResponse>;
  quizResult: QuizResult;
  quizToFillOut?: Maybe<QuizToFillOut>;
  school: School;
  schools?: Maybe<Array<School>>;
  searchBadges: Array<Badge>;
  searchBlocks: Array<Block>;
  searchCohorts: Array<Cohort>;
  searchCourses: Array<Course>;
  searchFlows: FlowsWithFilters;
  searchForms: Array<Form>;
  searchModules: Array<Module>;
  searchNodes: Array<Node>;
  searchPrograms: Array<Program>;
  searchPromoCodes: Array<PromoCode>;
  searchPublicCourses: Array<Course>;
  searchPublicFlows: FlowsWithFilters;
  searchSessions: Array<Session>;
  searchTeams: Array<Team>;
  searchUsers: Array<User>;
  session?: Maybe<Session>;
  sessions: Array<Session>;
  sessionsByMentor: Array<Session>;
  sprint?: Maybe<Sprint>;
  sprints: Array<Sprint>;
  /** @deprecated Use user instead */
  student?: Maybe<User>;
  /** @deprecated Use searchUsers instead */
  students: Array<User>;
  survey: Survey;
  team?: Maybe<Team>;
  user?: Maybe<User>;
  userNexusTeams: Array<NexusTeam>;
};


export type QueryBadgeArgs = {
  badgeId: Scalars['ID']['input'];
};


export type QueryBlockArgs = {
  blockId: Scalars['ID']['input'];
};


export type QueryCalendarEventArgs = {
  calendarEventId: Scalars['ID']['input'];
};


export type QueryCalendarEventsByDateRangeArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  end: Scalars['String']['input'];
  programId?: InputMaybe<Scalars['ID']['input']>;
  responsibleId?: InputMaybe<Scalars['ID']['input']>;
  start: Scalars['String']['input'];
};


export type QueryCalendarEventsByResponsibleArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryCalendarEventsBySessionArgs = {
  sessionId: Scalars['ID']['input'];
};


export type QueryCertificateArgs = {
  certificateId: Scalars['ID']['input'];
};


export type QueryCheckApiKeyArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryCodelabArgs = {
  codelabId: Scalars['ID']['input'];
};


export type QueryCodelabAssignmentArgs = {
  codelabAssignmentId: Scalars['ID']['input'];
};


export type QueryCodelabAssignmentForUserArgs = {
  codelabId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
};


export type QueryCodelabResponseTeamProgressArgs = {
  codelabId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryCohortArgs = {
  cohortId: Scalars['ID']['input'];
};


export type QueryCommunityArgs = {
  communityId: Scalars['ID']['input'];
};


export type QueryCourseArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryCourseCohortPriceArgs = {
  cohortId: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCoursesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDeliverableInstancesArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFeedbackArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  entity?: InputMaybe<EntityName>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFlowArgs = {
  flowId: Scalars['ID']['input'];
};


export type QueryFormArgs = {
  formId: Scalars['ID']['input'];
};


export type QueryFormInstanceArgs = {
  formInstanceId: Scalars['ID']['input'];
};


export type QueryFormInstancesByEntityArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  formId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryFormResponseArgs = {
  cohortId: Scalars['ID']['input'];
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  formId: Scalars['ID']['input'];
};


export type QueryFormsArgs = {
  courseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGetAllowedPaymentMethodsArgs = {
  cohortId: Scalars['ID']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAssignmentCodeForUserArgs = {
  codelabId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
};


export type QueryGetCodelabSolutionsArgs = {
  codelabId: Scalars['ID']['input'];
  flowId: Scalars['ID']['input'];
};


export type QueryGetEntityChangeHistoryArgs = {
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetGradedDeliverablesHoursArgs = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetLiveSessionsHoursArgs = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGetMentorAvailabilityArgs = {
  getMentorAvailabilityInput: GetMentorAvailability;
};


export type QueryGetUserCodelabAssignmentArgs = {
  getUserCodelabAssignmentInput?: InputMaybe<GetUserCodelabAssignmentInput>;
};


export type QueryGetUserMetricsArgs = {
  getUserMetricsInput: GetUserMetricsInput;
};


export type QueryGetWorkReportHoursArgs = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGradeArgs = {
  cohortId: Scalars['ID']['input'];
  entity: EntityName;
  entityId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryInteractiveContentArgs = {
  interactiveContentId: Scalars['ID']['input'];
};


export type QueryInteractiveContentBySessionArgs = {
  sessionId: Scalars['ID']['input'];
};


export type QueryMentorArgs = {
  mentorId: Scalars['ID']['input'];
};


export type QueryMetricsArgs = {
  metricsSearchInput: MetricsSearchInput;
};


export type QueryModuleArgs = {
  moduleId: Scalars['ID']['input'];
};


export type QueryModuleListArgs = {
  moduleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryNexusTeamArgs = {
  teamId: Scalars['ID']['input'];
};


export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  organizationSearchInput: EntitySearchInput;
};


export type QueryPlatformFeedbackArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductsArgs = {
  category: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProgramArgs = {
  programId: Scalars['ID']['input'];
};


export type QueryPublicUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryQuestionArgs = {
  questionId: Scalars['ID']['input'];
};


export type QueryQuizArgs = {
  quizId: Scalars['ID']['input'];
};


export type QueryQuizResponseArgs = {
  quizId: Scalars['ID']['input'];
};


export type QueryQuizResultArgs = {
  quizId: Scalars['ID']['input'];
  quizResponseId: Scalars['ID']['input'];
};


export type QueryQuizToFillOutArgs = {
  quizToFillOutId: Scalars['ID']['input'];
};


export type QuerySchoolArgs = {
  schoolId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySchoolsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchBadgesArgs = {
  badgeSearchInput: EntitySearchInput;
};


export type QuerySearchBlocksArgs = {
  entitySearchInput: EntitySearchInput;
};


export type QuerySearchCohortsArgs = {
  cohortSearchInput: EntitySearchInput;
};


export type QuerySearchCoursesArgs = {
  courseSearchInput: EntitySearchInput;
};


export type QuerySearchFlowsArgs = {
  entitySearchInput: EntitySearchInput;
};


export type QuerySearchFormsArgs = {
  formSearchInput: EntitySearchInput;
};


export type QuerySearchModulesArgs = {
  moduleSearchInput: EntitySearchInput;
};


export type QuerySearchNodesArgs = {
  entitySearchInput: EntitySearchInput;
};


export type QuerySearchProgramsArgs = {
  programSearchInput: EntitySearchInput;
};


export type QuerySearchPromoCodesArgs = {
  promoCodeSearchInput: EntitySearchInput;
};


export type QuerySearchPublicCoursesArgs = {
  courseSearchInput: EntitySearchInput;
};


export type QuerySearchPublicFlowsArgs = {
  entitySearchInput: EntitySearchInput;
};


export type QuerySearchSessionsArgs = {
  sessionSearchInput: EntitySearchInput;
};


export type QuerySearchTeamsArgs = {
  teamSearchInput: EntitySearchInput;
};


export type QuerySearchUsersArgs = {
  userSearchInput: EntitySearchInput;
};


export type QuerySessionArgs = {
  sessionId: Scalars['ID']['input'];
};


export type QuerySessionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  moduleId: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySessionsByMentorArgs = {
  mentorId: Scalars['ID']['input'];
};


export type QuerySprintArgs = {
  sprintId: Scalars['ID']['input'];
};


export type QuerySprintsArgs = {
  courseId: Scalars['ID']['input'];
};


export type QueryStudentArgs = {
  studentId: Scalars['ID']['input'];
};


export type QuerySurveyArgs = {
  surveyId: Scalars['ID']['input'];
};


export type QueryTeamArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  teamId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserNexusTeamsArgs = {
  entitySearchInput?: InputMaybe<EntitySearchInput>;
  flowId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Question = {
  __typename?: 'Question';
  answerGroups?: Maybe<Array<AnswerOptionGroup>>;
  answers: Array<AnswerOption>;
  description: Array<TranslatedField>;
  hasMultipleAnswers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  questionType: QuestionType;
  score: Scalars['Int']['output'];
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  selectedAnswerId: Scalars['ID']['output'];
};

export type QuestionResponseInput = {
  selectedAnswerId: Scalars['ID']['input'];
};

export type QuestionResultData = {
  __typename?: 'QuestionResultData';
  earnedPoints: Scalars['Float']['output'];
  isQuestionCorrect: Scalars['Boolean']['output'];
  questionId: Scalars['ID']['output'];
  questionScore: Scalars['Float']['output'];
  totalPoints: Scalars['Float']['output'];
};

export type QuestionSurvey = {
  __typename?: 'QuestionSurvey';
  description?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID']['output'];
  options?: Maybe<Array<OptionSurvey>>;
  title: Array<TranslatedField>;
};

export enum QuestionType {
  Choice = 'CHOICE',
  CompleteParagraph = 'COMPLETE_PARAGRAPH'
}

export type QuestionsResultsDisplayData = {
  __typename?: 'QuestionsResultsDisplayData';
  correctAnswers: Array<Scalars['ID']['output']>;
  correctResponses: Array<Scalars['ID']['output']>;
  incorrectResponses: Array<Scalars['ID']['output']>;
  questionResults: Array<QuestionResultData>;
};

export type Quiz = {
  __typename?: 'Quiz';
  allowResponseUpdate: Scalars['Boolean']['output'];
  flowId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isGradeable: Scalars['Boolean']['output'];
  questionIds: Array<Scalars['ID']['output']>;
  questions: Array<QuizQuestion>;
  shuffledAnswers: Scalars['Boolean']['output'];
};

export type QuizQuestion = {
  __typename?: 'QuizQuestion';
  answers: Array<AnswerOption>;
  description: Array<TranslatedField>;
  hasMultipleAnswers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  questionType: QuestionType;
  quizId: Scalars['ID']['output'];
  score: Scalars['Int']['output'];
};

export type QuizQuestionGrade = {
  __typename?: 'QuizQuestionGrade';
  earnedPoints: Scalars['Float']['output'];
  isQuestionCorrect: Scalars['Boolean']['output'];
  questionId: Scalars['ID']['output'];
  questionScore: Scalars['Float']['output'];
  totalPoints: Scalars['Float']['output'];
};

export type QuizQuestionsResults = {
  __typename?: 'QuizQuestionsResults';
  correctAnswers: Array<Scalars['ID']['output']>;
  correctAnswersIds: Array<Scalars['ID']['output']>;
  incorrectAnswersIds: Array<Scalars['ID']['output']>;
  questionsResults: Array<QuizQuestionGrade>;
};

export type QuizResponse = {
  __typename?: 'QuizResponse';
  id: Scalars['ID']['output'];
  quizId: Scalars['ID']['output'];
  selectedAnswersIds: Array<Scalars['ID']['output']>;
  userId: Scalars['ID']['output'];
};

export type QuizResult = {
  __typename?: 'QuizResult';
  quizQuestionResult: QuizQuestionsResults;
  quizResponse: QuizResponse;
};

export type QuizToFillOut = {
  __typename?: 'QuizToFillOut';
  allowResponseUpdate: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  questions: Array<UserQuizQuestion>;
  shuffledAnswers: Scalars['Boolean']['output'];
};

export type Rating = {
  __typename?: 'Rating';
  average: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
};

export type RemoteCodeRunnerResponse = {
  __typename?: 'RemoteCodeRunnerResponse';
  code?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  output?: Maybe<Scalars['String']['output']>;
};

export type RemoveUserBadgeInput = {
  badgeId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type Responsible = {
  __typename?: 'Responsible';
  role: ResponsibleRole;
  user: User;
};

export type ResponsibleComment = {
  __typename?: 'ResponsibleComment';
  comments: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export type ResponsibleInput = {
  role: ResponsibleRole;
  userId: Scalars['ID']['input'];
};

export enum ResponsibleRole {
  Mentor = 'MENTOR',
  Shadow = 'SHADOW',
  Support = 'SUPPORT'
}

export type School = BaseSchool & {
  __typename?: 'School';
  allowedIntegrations: Array<Scalars['String']['output']>;
  allowedLanguages?: Maybe<Array<Scalars['String']['output']>>;
  allowedPaymentMethods: Array<PaymentMethod>;
  conductCodeUrl?: Maybe<Scalars['String']['output']>;
  discord?: Maybe<DiscordConfig>;
  domain: Array<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  faviconUrl?: Maybe<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  frontendFeatures: Array<Scalars['String']['output']>;
  googleAnalytics?: Maybe<GoogleAnalyticsConfig>;
  gradingPolicyUrl?: Maybe<Scalars['String']['output']>;
  headerAccent?: Maybe<Scalars['String']['output']>;
  headerColor?: Maybe<Scalars['String']['output']>;
  heroBackground?: Maybe<Scalars['String']['output']>;
  heroImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  limits?: Maybe<SchoolUsageLimits>;
  linkedInId?: Maybe<Scalars['String']['output']>;
  loginLogoUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mailgun?: Maybe<MailgunConfig>;
  mailing?: Maybe<MailingConfig>;
  name: Scalars['String']['output'];
  notificationSourceEmail?: Maybe<Scalars['String']['output']>;
  onboardingFlowId?: Maybe<Scalars['ID']['output']>;
  payU?: Maybe<PayUConfig>;
  preferredLanguage?: Maybe<Scalars['String']['output']>;
  privacyUrl?: Maybe<Scalars['String']['output']>;
  stripe?: Maybe<StripeConfig>;
  termsUrl?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Theme>;
  usage: SchoolUsage;
};

export type SchoolUsage = {
  __typename?: 'SchoolUsage';
  currentMonthActiveUsers: Scalars['Int']['output'];
  enrolledSeats: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  schoolID: Scalars['ID']['output'];
  totalCohorts: Scalars['Int']['output'];
  totalCourses: Scalars['Int']['output'];
  totalPrograms: Scalars['Int']['output'];
  totalUsers: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SchoolUsageLimits = {
  __typename?: 'SchoolUsageLimits';
  coursesLimit?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  seatsLimit?: Maybe<Scalars['Int']['output']>;
  subscriptionName?: Maybe<Scalars['String']['output']>;
};

export type SendCohortInvitesInput = {
  cohortId: Scalars['ID']['input'];
  domain: Scalars['String']['input'];
  invites: Array<InviteInput>;
  message?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type SendInvitesInput = {
  domain: Scalars['String']['input'];
  invites: Array<InviteInput>;
};

export type Session = {
  __typename?: 'Session';
  activityType: ActivityType;
  calendarEvents?: Maybe<Array<CalendarEvent>>;
  course?: Maybe<Course>;
  courseId: Scalars['ID']['output'];
  duration: Scalars['Float']['output'];
  feedbackRating?: Maybe<Rating>;
  goals: Array<LearningGoal>;
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  interactiveContent?: Maybe<Array<InteractiveContent>>;
  moduleId: Scalars['ID']['output'];
  moduleInfo: SessionModuleInfo;
  name: Array<TranslatedField>;
  prerecordingLink?: Maybe<Scalars['String']['output']>;
  presentationLink?: Maybe<Scalars['String']['output']>;
  rank: Scalars['String']['output'];
  userProgress?: Maybe<Progress>;
};


export type SessionCalendarEventsArgs = {
  cohortId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SessionUserProgressArgs = {
  cohortId: Scalars['ID']['input'];
};

export type SessionModuleInfo = {
  __typename?: 'SessionModuleInfo';
  /** @deprecated No longer supported, will be removed in future versions */
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Array<TranslatedField>>;
  rank: Scalars['String']['output'];
};

export enum SkillType {
  HumanSkill = 'HUMAN_SKILL',
  TechnicalSkill = 'TECHNICAL_SKILL'
}

export type SlackNotificationConfig = {
  __typename?: 'SlackNotificationConfig';
  slackChannel: Scalars['String']['output'];
};

export type Sprint = {
  __typename?: 'Sprint';
  code: Scalars['String']['output'];
  goals: Array<LearningGoal>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sessions: Array<Session>;
};

export type StripeConfig = {
  __typename?: 'StripeConfig';
  endpointSecret: Scalars['String']['output'];
  publishableKey: Scalars['String']['output'];
  secretKey: Scalars['String']['output'];
};

export type StripeConfigInput = {
  endpointSecret: Scalars['String']['input'];
  publishableKey: Scalars['String']['input'];
  secretKey: Scalars['String']['input'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  name: Scalars['String']['output'];
  stripeId: Scalars['String']['output'];
};

export type StripeResponse = {
  __typename?: 'StripeResponse';
  checkoutUrl: Scalars['String']['output'];
};

export type StripeSubscription = {
  __typename?: 'StripeSubscription';
  subscriptionId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SubscribeMeInput = {
  paymentType: SubscriptionPaymentType;
  priceId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type SubscribeMeResponse = {
  __typename?: 'SubscribeMeResponse';
  stripeRedirectURL?: Maybe<Scalars['String']['output']>;
  subscriptionId: Scalars['ID']['output'];
};

export type SubscribeSchoolInput = {
  paymentType: SubscriptionPaymentType;
  priceId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
  schoolId: Scalars['ID']['input'];
};

export enum SubscriptionBillingPeriodType {
  Every_6Months = 'EVERY_6_MONTHS',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type SubscriptionInvoice = {
  __typename?: 'SubscriptionInvoice';
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  status: InvoiceStatus;
  statusUpdates: Array<SubscriptionInvoiceStatusUpdate>;
  stripeId?: Maybe<Scalars['String']['output']>;
  subscription: UserSubscription;
  total: Scalars['Float']['output'];
};

export type SubscriptionInvoiceStatusUpdate = {
  __typename?: 'SubscriptionInvoiceStatusUpdate';
  createdAt: Scalars['DateTime']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  status: InvoiceStatus;
};

export enum SubscriptionPaymentType {
  Invoice = 'INVOICE',
  Stripe = 'STRIPE'
}

export type SubscriptionPrice = {
  __typename?: 'SubscriptionPrice';
  billingPeriod: SubscriptionBillingPeriodType;
  currency: Scalars['String']['output'];
  discount?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  stripeId?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  category: Scalars['String']['output'];
  features: Array<TranslatedField>;
  id: Scalars['ID']['output'];
  isEnabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  prices: Array<SubscriptionPrice>;
  schoolId: Scalars['ID']['output'];
  stripeProduct?: Maybe<StripeProduct>;
};

export enum SubscriptionState {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Created = 'CREATED',
  PastDue = 'PAST_DUE',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID'
}

export type Survey = {
  __typename?: 'Survey';
  badgeId: Scalars['ID']['output'];
  description?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Array<TranslatedField>;
  questions: Array<QuestionSurvey>;
};

export type SurveyOptionResponse = {
  __typename?: 'SurveyOptionResponse';
  id: Scalars['ID']['output'];
  optionId: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
};

export type SurveyQuestionResponse = {
  __typename?: 'SurveyQuestionResponse';
  answer?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  questionId: Scalars['ID']['output'];
  selectOptions: Array<SurveyOptionResponse>;
};

export type SurveyResponse = {
  __typename?: 'SurveyResponse';
  answers: Array<SurveyQuestionResponse>;
  id: Scalars['ID']['output'];
  surveyId: Scalars['ID']['output'];
};

export type Team = {
  __typename?: 'Team';
  assignmentSolutions?: Maybe<Array<TeamAssignmentSolution>>;
  cohort?: Maybe<Cohort>;
  cohortId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  members: Array<PublicUser>;
  name: Scalars['String']['output'];
};

export type TeamAssignment = {
  __typename?: 'TeamAssignment';
  assignments: Array<Assignment>;
  id: Scalars['ID']['output'];
  token: Scalars['String']['output'];
};

export type TeamAssignmentInput = {
  assignments: Array<AssignmentInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamAssignmentSolution = {
  __typename?: 'TeamAssignmentSolution';
  cohortId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  interactiveContent: InteractiveContent;
  interactiveContentId: Scalars['ID']['output'];
  solutions: Array<TeamAssignmentUserSolution>;
  team: Team;
  teamId: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userAssignment?: Maybe<Assignment>;
};

export type TeamAssignmentUserSolution = {
  __typename?: 'TeamAssignmentUserSolution';
  assignmentId: Scalars['ID']['output'];
  files?: Maybe<Array<AssignmentFile>>;
  status: AssignmentStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
};

export type TeamAssignmentUserSolutionInput = {
  files?: InputMaybe<Array<AssignmentFileInput>>;
  teamAssignmentId: Scalars['ID']['input'];
  teamSolutionId: Scalars['ID']['input'];
  token: Scalars['String']['input'];
};

export type Theme = {
  __typename?: 'Theme';
  colors?: Maybe<ThemeColors>;
};

export type ThemeColors = {
  __typename?: 'ThemeColors';
  dark?: Maybe<Scalars['JSON']['output']>;
  light?: Maybe<Scalars['JSON']['output']>;
  primary?: Maybe<Scalars['JSON']['output']>;
  secondary?: Maybe<Scalars['JSON']['output']>;
  tertiary?: Maybe<Scalars['JSON']['output']>;
};

export type ThemeColorsInput = {
  dark?: InputMaybe<Scalars['JSON']['input']>;
  light?: InputMaybe<Scalars['JSON']['input']>;
  primary?: InputMaybe<Scalars['JSON']['input']>;
  secondary?: InputMaybe<Scalars['JSON']['input']>;
  tertiary?: InputMaybe<Scalars['JSON']['input']>;
};

export type ThemeInput = {
  colors?: InputMaybe<ThemeColorsInput>;
};

export type TotalPeriodHours = {
  __typename?: 'TotalPeriodHours';
  comments?: Maybe<Scalars['String']['output']>;
  period: Scalars['DateTime']['output'];
  totalHours: Scalars['Float']['output'];
};

export type TotalRoleHours = {
  __typename?: 'TotalRoleHours';
  mentoring: Array<TotalPeriodHours>;
  preparation: Array<TotalPeriodHours>;
  shadowing: Array<TotalPeriodHours>;
};

export type TrackEventInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  context?: InputMaybe<Scalars['JSON']['input']>;
  entity?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  event: Scalars['String']['input'];
  sourceURL: Scalars['String']['input'];
};

export type Training = Course | Program;

export type TranslatedField = {
  __typename?: 'TranslatedField';
  locale: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type TranslatedFieldInput = {
  locale: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateAnswerOptionGroupInput = {
  answers: Array<UpdateAnswerOptionInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateAnswerOptionInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  explication?: InputMaybe<Array<TranslatedFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isCorrectAnswer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateBadgeInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  descriptors?: InputMaybe<Array<DescriptorInput>>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type UpdateBlockInput = {
  blockType?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  order?: InputMaybe<Scalars['String']['input']>;
  privateData?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']['input']>;
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  liveSessionLink?: InputMaybe<Scalars['String']['input']>;
  presentationLink?: InputMaybe<Scalars['String']['input']>;
  recordingLink?: InputMaybe<Scalars['String']['input']>;
  responsibles?: InputMaybe<Array<ResponsibleInput>>;
  sessionId?: InputMaybe<Scalars['ID']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateCertificateInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  partnerOrgIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type UpdateCodelabAssignmentInput = {
  description: Array<TranslatedFieldInput>;
  id: Scalars['ID']['input'];
  programmingLanguage: Scalars['String']['input'];
  solutions?: InputMaybe<Array<AssignmentFileInput>>;
  test: AssignmentFileInput;
  testCommand: Scalars['String']['input'];
  testCommandWindows: Scalars['String']['input'];
};

export type UpdateCodelabInput = {
  branch?: InputMaybe<Scalars['String']['input']>;
  description: Array<TranslatedFieldInput>;
  flowId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  repoURL: Scalars['String']['input'];
};

export type UpdateCohortInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  defaultLiveSessionLink?: InputMaybe<Scalars['String']['input']>;
  discordChannel?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  prices?: InputMaybe<Array<PriceInput>>;
  sessionDefaultDuration?: InputMaybe<Scalars['Int']['input']>;
  sessionStatus?: InputMaybe<CohortSessionStatus>;
  slackChannel?: InputMaybe<Scalars['String']['input']>;
  sponsorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<CohortStatus>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  tutorsIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  visibility?: InputMaybe<CohortVisibility>;
  weekdays?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type UpdateCommunityInput = {
  accessType?: InputMaybe<AccessType>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConnectionInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['ID']['input'];
  sourceId?: InputMaybe<Scalars['ID']['input']>;
  targetId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateCourseInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  details?: InputMaybe<Array<TranslatedFieldInput>>;
  holidays?: InputMaybe<Array<HolidayInput>>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Array<PublicOrganizationWithRoleInput>>;
  status?: InputMaybe<CourseStatus>;
  translatedName?: InputMaybe<Array<TranslatedFieldInput>>;
  visibility?: InputMaybe<CourseVisibility>;
};

export type UpdateFlowInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  status?: InputMaybe<FlowStatus>;
  topics?: InputMaybe<Array<Scalars['String']['input']>>;
  visibility?: InputMaybe<FlowVisibility>;
};

export type UpdateFormInput = {
  allowResponseUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isGradeable?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  shuffledAnswers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFormInstanceInput = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  entity?: InputMaybe<EntityName>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateInteractiveContentInput = {
  assignment?: InputMaybe<AssignmentInput>;
  codeRunnerGradePercentage?: InputMaybe<Scalars['Int']['input']>;
  codeRunnerTests?: InputMaybe<Array<CodeRunnerTestInput>>;
  codeRunnerType?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<InteractiveContentType>;
  defaultDeliverableContent?: InputMaybe<Scalars['String']['input']>;
  deliverableGradingMethod?: InputMaybe<DeliverableGradingMethod>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  formId?: InputMaybe<Scalars['ID']['input']>;
  hiddenDeliverableContent?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  preliminaryContent?: InputMaybe<Array<TranslatedFieldInput>>;
  programmingLanguages?: InputMaybe<Array<Scalars['String']['input']>>;
  prompt?: InputMaybe<Array<TranslatedFieldInput>>;
  question?: InputMaybe<Array<TranslatedFieldInput>>;
  rank?: InputMaybe<Scalars['String']['input']>;
  solution?: InputMaybe<AssignmentFileInput>;
  teamAssignment?: InputMaybe<TeamAssignmentInput>;
  title?: InputMaybe<Array<TranslatedFieldInput>>;
  video?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type UpdateMentorAvailability = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<MentorAvailabilityStatus>;
};

export type UpdateModuleInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  prerequisitesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  rank?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNodeInput = {
  data?: InputMaybe<Scalars['JSON']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  nodeType?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  position?: InputMaybe<NodePositionInput>;
  size?: InputMaybe<NodeSizeInput>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProgramInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<ProgramItemInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CourseStatus>;
  visibility?: InputMaybe<CourseVisibility>;
};

export type UpdateQuestionInput = {
  answerGroups?: InputMaybe<Array<UpdateAnswerOptionGroupInput>>;
  answers?: InputMaybe<Array<UpdateAnswerOptionInput>>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateQuizAnswerGroupInput = {
  answers: Array<UpdateQuizAnswerInput>;
  id: Scalars['ID']['input'];
};

export type UpdateQuizAnswerInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  explication?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID']['input'];
  isCorrectAnswer?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateQuizInput = {
  allowResponseUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isGradeable?: InputMaybe<Scalars['Boolean']['input']>;
  shuffledAnswers?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateQuizQuestionInput = {
  answerGroups?: InputMaybe<Array<UpdateQuizAnswerGroupInput>>;
  answers?: InputMaybe<Array<UpdateQuizAnswerInput>>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID']['input'];
  score?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateSchoolInput = {
  allowedIntegrations: Array<Scalars['String']['input']>;
  allowedPaymentMethods?: InputMaybe<Array<PaymentMethod>>;
  conductCodeUrl?: InputMaybe<Scalars['String']['input']>;
  createdByUserId?: InputMaybe<Scalars['String']['input']>;
  discord?: InputMaybe<DiscordConfigInput>;
  domain?: InputMaybe<Array<Scalars['String']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  faviconUrl?: InputMaybe<Scalars['String']['input']>;
  features?: InputMaybe<Array<Scalars['String']['input']>>;
  googleAnalytics?: InputMaybe<GoogleAnalyticsConfigInput>;
  gradingPolicyUrl?: InputMaybe<Scalars['String']['input']>;
  headerAccent?: InputMaybe<Scalars['String']['input']>;
  headerColor?: InputMaybe<Scalars['String']['input']>;
  heroBackground?: InputMaybe<Scalars['String']['input']>;
  heroImageUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  linkedInId?: InputMaybe<Scalars['String']['input']>;
  loginLogoUrl?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  mailgun?: InputMaybe<MailgunConfigInput>;
  mailing?: InputMaybe<MailingConfigInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSourceEmail?: InputMaybe<Scalars['String']['input']>;
  onboardingFlowId?: InputMaybe<Scalars['ID']['input']>;
  payU?: InputMaybe<PayUConfigInput>;
  privacyUrl?: InputMaybe<Scalars['String']['input']>;
  stripe?: InputMaybe<StripeConfigInput>;
  termsUrl?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<ThemeInput>;
};

export type UpdateSessionInput = {
  activityType?: InputMaybe<ActivityType>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  goals?: InputMaybe<Array<LearningGoalInput>>;
  id: Scalars['ID']['input'];
  moduleId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  prerecordingLink?: InputMaybe<Scalars['String']['input']>;
  presentationLink?: InputMaybe<Scalars['String']['input']>;
  rank?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  id: Scalars['ID']['input'];
  memberIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  gitHubUsername?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<UserRole>>;
  uploadedAvatarUrl?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserProfileInput = {
  avatar?: InputMaybe<Scalars['JSON']['input']>;
  avatarImageURL?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  cityOfResidence?: InputMaybe<Scalars['String']['input']>;
  countryOfResidence?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  educationInstitution?: InputMaybe<Scalars['String']['input']>;
  employmentStatus?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gitHubProfileUrl?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<LanguageInput>>;
  linkedInProfileUrl?: InputMaybe<Scalars['String']['input']>;
  mentoringLevel?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stateOfResidence?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  vulnerablePopulation?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  apiKeys: Array<ApiKey>;
  assignedDeliverables: Array<Deliverable>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  badgeCount: Scalars['Int']['output'];
  badges?: Maybe<Array<Badge>>;
  certificates?: Maybe<Array<Certificate>>;
  code: Scalars['String']['output'];
  cohorts?: Maybe<Array<Cohort>>;
  cohortsWithRole?: Maybe<Array<CohortWithRole>>;
  coins?: Maybe<Coins>;
  courses?: Maybe<Array<Course>>;
  email: Scalars['String']['output'];
  emailVerified?: Maybe<Scalars['Boolean']['output']>;
  enrollmentInCohort?: Maybe<Enrollment>;
  experience?: Maybe<Experience>;
  firebaseUid?: Maybe<Scalars['String']['output']>;
  gitHubUsername?: Maybe<Scalars['String']['output']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID']['output'];
  invites: Array<Invite>;
  invoices: Array<SubscriptionInvoice>;
  name: Scalars['String']['output'];
  notifications: Array<Notification>;
  notificationsCount?: Maybe<Scalars['Int']['output']>;
  orders: Array<Maybe<Order>>;
  profile?: Maybe<UserProfile>;
  progress?: Maybe<Progress>;
  referrerCode?: Maybe<Scalars['String']['output']>;
  roles: Array<UserRole>;
  school: School;
  subscriptions: Array<UserSubscription>;
  teams: Array<Team>;
  uploadedAvatarUrl?: Maybe<Scalars['String']['output']>;
};


export type UserApiKeysArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserAssignedDeliverablesArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  interactiveContentId?: InputMaybe<Scalars['ID']['input']>;
  isGraded?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  userIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type UserBadgesArgs = {
  badgesIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type UserCertificatesArgs = {
  badgeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type UserCohortsArgs = {
  cohortId?: InputMaybe<Scalars['ID']['input']>;
  courseId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  programId?: InputMaybe<Scalars['ID']['input']>;
};


export type UserCohortsWithRoleArgs = {
  courseId?: InputMaybe<Scalars['ID']['input']>;
  includeBlocked?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserEnrollmentInCohortArgs = {
  cohortId: Scalars['ID']['input'];
};


export type UserGradeArgs = {
  cohortId: Scalars['ID']['input'];
  entity: EntityName;
  entityId: Scalars['ID']['input'];
};


export type UserInvoicesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  subscriptionId: Scalars['ID']['input'];
};


export type UserNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  userNotificationFilter?: InputMaybe<UserNotificationFilter>;
};


export type UserNotificationsCountArgs = {
  userNotificationFilter?: InputMaybe<UserNotificationFilter>;
};


export type UserOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type UserProgressArgs = {
  cohortId: Scalars['ID']['input'];
  entity: EntityName;
  entityId: Scalars['ID']['input'];
};


export type UserSubscriptionsArgs = {
  category?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<SubscriptionState>;
};


export type UserTeamsArgs = {
  teamSearchInput?: InputMaybe<EntitySearchInput>;
};

export type UserAnswerOption = {
  __typename?: 'UserAnswerOption';
  description: Array<TranslatedField>;
  id: Scalars['ID']['output'];
};

export type UserCodelabAssignment = {
  __typename?: 'UserCodelabAssignment';
  assignmentDescription: Array<TranslatedField>;
  codelabDescription: Array<TranslatedField>;
  codelabId: Scalars['ID']['output'];
  idAsssignment: Scalars['ID']['output'];
};

export type UserMetrics = {
  __typename?: 'UserMetrics';
  UpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  metrics: Array<Metric>;
  name: Scalars['String']['output'];
};

export type UserNotificationFilter = {
  channel?: InputMaybe<NotificationChannel>;
  statuses?: InputMaybe<Array<InputMaybe<NotificationStatus>>>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatar?: Maybe<Scalars['JSON']['output']>;
  avatarImageURL?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['DateTime']['output']>;
  cityOfResidence?: Maybe<Scalars['String']['output']>;
  countryOfResidence?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  educationInstitution?: Maybe<Scalars['String']['output']>;
  employmentStatus?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gitHubProfileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  languages?: Maybe<Array<Language>>;
  linkedInProfileUrl?: Maybe<Scalars['String']['output']>;
  mentoringLevel?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  stateOfResidence?: Maybe<Scalars['String']['output']>;
  vulnerablePopulation?: Maybe<Scalars['String']['output']>;
};

export type UserQuizQuestion = {
  __typename?: 'UserQuizQuestion';
  answers: Array<UserAnswerOption>;
  description: Array<TranslatedField>;
  hasMultipleAnswers: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  questionType: QuestionType;
  quizId: Scalars['ID']['output'];
  score: Scalars['Int']['output'];
};

export enum UserRole {
  Admin = 'ADMIN',
  AdminReadonly = 'ADMIN_READONLY',
  Creator = 'CREATOR',
  Guest = 'GUEST',
  Manager = 'MANAGER',
  Member = 'MEMBER',
  Mentor = 'MENTOR',
  PlatformAdmin = 'PLATFORM_ADMIN',
  Reporter = 'REPORTER',
  Sales = 'SALES',
  SchoolAdmin = 'SCHOOL_ADMIN',
  Sponsor = 'SPONSOR',
  Student = 'STUDENT',
  Tutor = 'TUTOR'
}

export type UserSubscription = {
  __typename?: 'UserSubscription';
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  ownerIds: Array<Scalars['ID']['output']>;
  paymentType: SubscriptionPaymentType;
  price: SubscriptionPrice;
  product: PublicSubscriptionProduct;
  schoolId: Scalars['ID']['output'];
  startDate: Scalars['DateTime']['output'];
  state: SubscriptionState;
  stripeSubscription?: Maybe<StripeSubscription>;
  trialEndDate?: Maybe<Scalars['DateTime']['output']>;
};

export type VirtualAssistantResponse = {
  __typename?: 'VirtualAssistantResponse';
  batteryLevel: Scalars['Int']['output'];
  response: Scalars['String']['output'];
};

export type WorkReport = {
  __typename?: 'WorkReport';
  category?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  start: Scalars['DateTime']['output'];
  totalHours: Scalars['Float']['output'];
  userId: Scalars['ID']['output'];
};
